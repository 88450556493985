import React from 'react';

export default function NotePlate({ note, transform, arrowClass, className }) {
  return (
    <div
      className={`school-layout__note-plate ${className}`}
      style={{ transform: `translate(${transform.x}px , ${transform.y}px)` }}
    >
      <p>{note}</p>
      <div className={`school-layout__note-arrow ${arrowClass}`}></div>
    </div>
  );
}
