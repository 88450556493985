import { useFrame } from '@react-three/fiber';

function RenderOverride() {
  useFrame(({ gl, scene, camera }) => {
    gl.render(scene, camera);
  }, true);

  return null;
}

export default RenderOverride;
