export const SCENE_TYPES = {
  AERIAL: 'AERIAL',
  LANDING: 'LANDING',
  GROUND: 'GROUND',
};

export const isAerial = (type) =>
  String(type).toUpperCase() === SCENE_TYPES.AERIAL;

export default SCENE_TYPES;
