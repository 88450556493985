import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import MenuItem from './MenuItem';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';

const GalleryMenu = ({
  desktop,
  mobile,
  scenes,
  groups,
  onSelect,
  openMenu,
  handleOpenMenu,
  activeColor,
  currentPano,
}) => {
  const [selectedCard, setSelectedCard] = useState(null);

  const handleMenuToggle = () => {
    handleOpenMenu();
  };

  useEffect(() => {
    if (currentPano && groups.length) {
      const currentGroup = groups.find(
        (group) => group._id === currentPano.tourGroupId
      );
      setSelectedCard(currentGroup._id);
    } else setSelectedCard(null);
  }, [currentPano, groups]);

  return (
    <div
      className={`tour-menu menu-gallery ${desktop ? 'desktop' : ''} ${
        mobile ? 'mobile' : ''
      }  ${openMenu ? 'menu-open' : 'menu-close'}`}
    >
      {desktop ? (
        <div className="arrow-up">&#x276E;</div>
      ) : (
        <div className="arrow-right">&#x276E;</div>
      )}
      <div className={`menu-items ${mobile ? 'menu-nonwrap' : ''}`}>
        <PerfectScrollbar
          options={{ suppressScrollX: desktop, suppressScrollY: mobile }}
        >
          {groups.map((group) => {
            return (
              <MenuItem
                key={group._id}
                scenes={scenes}
                sceneGroup={group}
                onSelect={onSelect}
                selectedCard={selectedCard}
                activeColor={activeColor}
                handleSelect={() => setSelectedCard(group._id)}
              />
            );
          })}
        </PerfectScrollbar>
      </div>
      {desktop ? (
        <div className="arrow-down">&#x276E;</div>
      ) : (
        <div className="arrow-left">&#x276E;</div>
      )}
      <div className="menu-toggle" onClick={handleMenuToggle}>
        <span className="menu-arrow-left">&#x276E;</span>
        <span className="menu-arrow-down">&#x276E;</span>
      </div>
    </div>
  );
};

export default GalleryMenu;
