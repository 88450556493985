import React, { useCallback, useMemo } from 'react';
import './LevelSwitch.scss';

import { sceneList, mikaSceneList, apartmentList } from './scene.helper';
import { isMobile } from 'react-device-detect';

export default function LevelSwitch({ currentPano, onSelect }) {
  const enable = useMemo(() => {
    if (currentPano?.id && sceneList.indexOf(currentPano?.id) !== -1) {
      return true;
    }
    return false;
  }, [currentPano]);

  const mikaEnable = useMemo(() => {
    if (currentPano?.id && mikaSceneList.indexOf(currentPano?.id) !== -1) {
      return true;
    }
    return false;
  }, [currentPano]);

  const aparmentEnable = useMemo(() => {
    if (
      (currentPano?.id && apartmentList.indexOf(currentPano?.id) !== -1) ||
      (currentPano?.id && +currentPano?.id)
    ) {
      return true;
    }
    return false;
  }, [currentPano]);

  const isLevel = useCallback(
    (lvl) => {
      if (enable && currentPano?.id === `topview-tang-${lvl}`) {
        return true;
      }

      if (mikaEnable && currentPano?.id === `mika-topview-tang-${lvl}`) {
        return true;
      }
      return false;
    },
    [currentPano, enable, mikaEnable]
  );

  const isTypeOfApartment = useMemo(() => {
    if (aparmentEnable && apartmentList.indexOf(currentPano?.id) !== -1) {
      return 1;
    }

    if (aparmentEnable && +currentPano?.id) {
      return 2;
    }

    return null;
  }, [currentPano, aparmentEnable]);

  const goToLevel = useCallback(
    (lvl) => {
      if (enable && currentPano?.id !== `topview-tang-${lvl}`) {
        onSelect('welcome', `topview-tang-${lvl}`);
      }

      if (mikaEnable && currentPano?.id !== `topview-tang-${lvl}`) {
        onSelect('welcome', `mika-topview-tang-${lvl}`);
      }
    },
    [currentPano, onSelect, enable, mikaEnable]
  );

  return (
    <>
      <div
        className={`level-switch-wp ${
          enable ? 'switch-enable' : 'switch-disable'
        }`}
      >
        <div className="level-switch">
          <div className="level-switch-text">TẦNG</div>
          <div
            className={`level-switch-item ${isLevel('07') && 'active'}`}
            onClick={() => goToLevel('07')}
          >
            07
          </div>
          <div
            className={`level-switch-item ${isLevel('15') && 'active'}`}
            onClick={() => goToLevel('15')}
          >
            15
          </div>
          <div
            className={`level-switch-item ${isLevel('22') && 'active'}`}
            onClick={() => goToLevel('22')}
          >
            22
          </div>
          <div
            className={`level-switch-item ${isLevel('27') && 'active'}`}
            onClick={() => goToLevel('27')}
          >
            27
          </div>
        </div>
      </div>

      <div
        className={`level-switch-wp ${
          mikaEnable ? 'switch-enable' : 'switch-disable'
        }`}
      >
        <div className="level-switch mika-level-switch">
          <div className="level-switch-text">TẦNG</div>
          <div
            className={`level-switch-item ${isLevel('07') && 'active'}`}
            onClick={() => goToLevel('07')}
          >
            06
          </div>
          <div
            className={`level-switch-item ${isLevel('15') && 'active'}`}
            onClick={() => goToLevel('15')}
          >
            12
          </div>
          <div
            className={`level-switch-item ${isLevel('25') && 'active'}`}
            onClick={() => goToLevel('25')}
          >
            18
          </div>
          <div
            className={`level-switch-item ${isLevel('27') && 'active'}`}
            onClick={() => goToLevel('27')}
          >
            24
          </div>
        </div>
      </div>

      <div
        className={`level-switch-wp ${
          aparmentEnable ? 'switch-enable' : 'switch-disable'
        }`}
      >
        <div className="level-switch mika-level-switch apartment-switch">
          <div className="level-switch-text">CĂN HỘ</div>
          <div
            className={`level-switch-item apartment-switch-item ${
              isTypeOfApartment === 1 && 'active'
            }`}
            onClick={() => onSelect('can-ho-mau', 'can-ho-1pn-scene-1')}
          >
            {isMobile ? '1PN+' : `1 Phòng Ngủ +`}
          </div>
          <div
            className={`level-switch-item apartment-switch-item ${
              isTypeOfApartment === 2 && 'active'
            }`}
            onClick={() => onSelect('can-ho-mau', '2')}
          >
            {isMobile ? '2PN' : `2 Phòng Ngủ`}
          </div>
        </div>
      </div>
    </>
  );
}
