import React, { useMemo, memo, useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import { FrontSide } from 'three';

import configs from 'configs';
import {
  calcFootRotationY,
  calcScaleForProjection,
} from 'containers/world/utils';
import {
  STEP_WIDTH,
  TRANSFORM_RATIO,
  TOP_Y,
  DEFAULT_ROTATION_X_ANGLE,
  SCALE,
} from 'containers/world/constant';

const footStepImg =
  configs.baseUrl + '/assets/images/navigation/circleFixed.png';

function FootStep({ step }) {
  const meshRef = useRef();
  const { camera } = useThree();

  const scaleRef = useRef({
    factor: 0,
    x: 0,
    y: 0,
  });

  const texture = useTexture(footStepImg);

  const { stepPosition } = step;
  const [stepX, stepY, stepZ] = stepPosition;
  const scaledPosition = useMemo(
    () => [
      (TRANSFORM_RATIO * stepX) / SCALE,
      TRANSFORM_RATIO * stepY,
      (TRANSFORM_RATIO * stepZ) / SCALE,
    ],
    [stepX, stepY, stepZ]
  );

  useEffect(() => {
    const { scaleX, scaleY } = calcScaleForProjection(stepY);
    const angleRotate = calcFootRotationY(stepPosition);
    meshRef.current.visible = stepY <= TOP_Y;
    meshRef.current.scale.set(scaleX, scaleY);
    scaleRef.current.factor = 1;
    scaleRef.current.x = scaleX;
    scaleRef.current.y = scaleY;
    meshRef.current.rotation.set(DEFAULT_ROTATION_X_ANGLE, 0, angleRotate);
  }, [stepPosition, stepY, camera.position]);

  useFrame(() => {
    if (meshRef.current && scaleRef.current.factor !== 0) {
      if (scaleRef.current.factor > 0) {
        const newScale = {
          x: meshRef.current.scale.x + 0.0025,
          y: meshRef.current.scale.y + 0.0025,
          z: meshRef.current.scale.z + 0.0025,
        };
        if (meshRef.current.scale.x > scaleRef.current.x * SCALE) {
          scaleRef.current.factor = -1;
        } else {
          meshRef.current.scale.set(newScale.x, newScale.y, newScale.z);
        }
      } else {
        const newScale = {
          x: meshRef.current.scale.x - 0.0025,
          y: meshRef.current.scale.y - 0.0025,
          z: meshRef.current.scale.z - 0.0025,
        };
        if (meshRef.current.scale.x < scaleRef.current.x) {
          scaleRef.current.factor = 1;
        } else {
          meshRef.current.scale.set(newScale.x, newScale.y, newScale.z);
        }
      }
    }
  });

  return (
    <mesh ref={meshRef} position={scaledPosition}>
      <planeGeometry args={[STEP_WIDTH, STEP_WIDTH]} />
      <meshPhongMaterial
        color="white"
        map={texture}
        alphaTest={0.5}
        side={FrontSide}
        transparent
        opacity={1.5}
      />
    </mesh>
  );
}

export default memo(FootStep);
