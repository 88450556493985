import React from 'react';

export const FloorMapIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g width="30" height="30" transform="translate(22497 1491)">
        <g transform="translate(-2.999 4)">
          <path
            fill="#fff"
            d="M715.256,198.72l6.414,2.85,0-18.152-6.418-2.85Z"
            transform="translate(-23197.01 -1671.568)"
          />
          <path
            fill="#fff"
            d="M723.5,201.5l5.5-2.749V180.64l-5.5,2.749Z"
            transform="translate(-23197.01 -1671.568)"
          />
          <path
            fill="#fff"
            d="M707.01,201.57l6.414-2.85V180.568l-6.414,2.85Z"
            transform="translate(-23197.01 -1671.568)"
          />
        </g>
      </g>
    </svg>
  );
};
