import React, { useMemo } from 'react';
import isURL from 'validator/lib/isURL';

import './InfoTag.scss';

const handleReadmoreURL = (readMoreURL) => {
  if (
    readMoreURL.split(':')[0] === 'https' ||
    readMoreURL.split(':')[0] === 'http'
  ) {
    return readMoreURL;
  }
  return '//' + readMoreURL;
};

const InfoTag = ({ detail }) => {
  const up = useMemo(
    () => detail.hotspot.location[1] > 0,
    [detail.hotspot.location]
  );
  const styles = useMemo(() => {
    return {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
      bottom: up ? '0' : null,
    };
  }, [up]);
  return (
    <div className="info-tag inner-outline" style={styles || {}}>
      <div className="media-text ">
        <div className="title shadow-text" style={{ color: 'black' }}>
          <h1>{detail.data.title}</h1>
        </div>
        <p className="wrap-text" value={detail.data.infoTag?.text}>
          {detail.data.infoTag?.text}
        </p>
        {detail.data.infoTag?.readMoreUrl &&
          isURL(detail.data.infoTag?.readMoreUrl) && (
            <a
              className="card-link"
              href={handleReadmoreURL(detail.data.infoTag?.readMoreUrl)}
              target="_blank"
              rel="noreferrer"
            >
              <h5 className="infotag-title">Read more</h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13"
                fill="currentColor"
                className="bi bi-box-arrow-up-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                />
                <path
                  fillRule="evenodd"
                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                />
              </svg>
            </a>
          )}
      </div>
    </div>
  );
};

export default InfoTag;
