import React from 'react';
import { CANVAS_W, CANVAS_H, CANVAS_STYLE } from './utils';

export const Canvas = React.forwardRef((props, ref) => {
  return (
    <canvas ref={ref} width={CANVAS_W} height={CANVAS_H} style={CANVAS_STYLE} />
  );
});

export default React.memo(Canvas);
