import React from 'react';
import { connect } from 'react-redux';
import DomErrorBoundary from 'components/errors/domError';
import FirstRoute from './1stRoute';
import SecondRoute from './2ndRoute';
import ThirdRoute from './3rdRoute';
import FourthRoute from './4thRoute';

const RouteHandlers = (props) => (
  <DomErrorBoundary>
    <FirstRoute {...props} />
    <SecondRoute {...props} />
    <ThirdRoute {...props} />
    <FourthRoute {...props} />
  </DomErrorBoundary>
);

const mapStateToProps = ({ json: { scenes, groups } }) => ({
  scenes,
  groups,
});

export default connect(mapStateToProps)(RouteHandlers);
