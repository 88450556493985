import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import MEDIA_TYPES from '../../consts/mediaType.const';
import AmbientAudio from './ambient';

const Audio = (props) => {
  const {
    available,
    enabled,
    scene: { hotspots },
  } = props;

  const { isOpenPopUp } = useSelector((state) => state);

  const [audioUrl, setAudioUrl] = useState([{ repeat: '', soundUrl: '' }]);
  // PR
  useEffect(() => {
    let aUrl = [];
    if (available && enabled && hotspots && !isOpenPopUp) {
      const hotspot = hotspots.filter(
        (h) => h.media && h.media.type === MEDIA_TYPES.AUDIO && h.media.soundUrl
      );
      const audioConfigs = hotspot.map((item) => ({
        repeat: item.configs ? JSON.parse(item.configs).audioRepeat : true,
        soundUrl: item.media.soundUrl,
      }));

      aUrl = hotspot ? audioConfigs : [{ repeat: '', soundUrl: '' }];
    }
    if (aUrl[0]?.soundUrl !== audioUrl[0]?.soundUrl) {
      setAudioUrl(aUrl);
      window.logMessage('audioUrl', aUrl);
    }
  }, [available, enabled, hotspots, audioUrl, isOpenPopUp]);

  return <AmbientAudio audios={audioUrl} />;
};

const mapStateToProps = ({ audio: { enabled, available } }) => ({
  enabled,
  available,
});

export default connect(mapStateToProps)(Audio);
