import React from 'react';

import iconWebsite from './IconWebsite.svg';
import iconFB from './IconFB.svg';
import iconInstagram from './IconInstagram.svg';
import iconTwitter from './IconTwitter.svg';
import iconYoutube from './IconYoutube.svg';
import iconEmail from './IconEmail.svg';
import iconPinterest from './IconPinterest.svg';
import iconSnapchat from './IconSnapchat.svg';

import './icon.scss';

const IconWebsite = () => {
  return <img src={iconWebsite} alt="icon-website" />;
};

const IconFacebook = () => {
  return <img src={iconFB} alt="icon-facebook" />;
};

const IconInstagram = () => {
  return <img src={iconInstagram} alt="icon-instagram" />;
};

const IconTwitter = () => {
  return <img src={iconTwitter} alt="icon-twitter" />;
};

const IconYoutube = () => {
  return <img src={iconYoutube} alt="icon-youtube" />;
};

const IconEmail = () => {
  return <img src={iconEmail} alt="icon-email" />;
};

const IconPinterest = () => {
  return <img src={iconPinterest} alt="icon-instagram" />;
};

const IconSnapchat = () => {
  return <img src={iconSnapchat} alt="icon-snapchat" />;
};

const Icons = (props) => {
  const { type, color } = props;
  switch (type) {
    case 'WEBSITE':
      return <IconWebsite color={color} />;
    case 'FACEBOOK':
      return <IconFacebook color={color} />;
    case 'INSTAGRAM':
      return <IconInstagram color={color} />;
    case 'TWITTER':
      return <IconTwitter color={color} />;
    case 'YOUTUBE':
      return <IconYoutube color={color} />;
    case 'PINTEREST':
      return <IconPinterest color={color} />;
    case 'EMAIL':
      return <IconEmail color={color} />;
    case 'PRINTEREST':
      return <IconPinterest color={color} />;
    case 'SNAPCHAT':
      return <IconSnapchat color={color} />;
    default:
      return <></>;
  }
};

const SocialIcon = (props) => {
  const { type, color, link } = props;
  return (
    <div className="social-icon">
      <div className="social-icon-svg">
        <a target="_blank" href={link} rel="noopener noreferrer">
          <Icons type={type} color={color} />
        </a>
      </div>
    </div>
  );
};

export default SocialIcon;
