import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateDimentions } from '../../store/actions';
import DIMENTION from '../../consts/dimention.const';

function useWindowSize() {
  const isClient = typeof window === 'object';
  function getWinSize() {
    return !isClient
      ? {
          width: 0,
          height: 0,
          dimention: DIMENTION.PORTRAIT,
        }
      : {
          width: window.innerWidth,
          height: window.innerHeight,
          dimention:
            window.innerWidth > window.innerHeight
              ? DIMENTION.LANDSCAPE
              : DIMENTION.PORTRAIT,
        };
  }
  const [winSize, setWinDim] = useState(getWinSize);
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWinDim(getWinSize());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);
  return winSize;
}

function SizeDetector(props) {
  const currDimentions = useWindowSize();
  useEffect(() => {
    if (currDimentions.width) {
      props.updateDimentions(currDimentions);
    }
  }, [props, currDimentions]);
  return <></>;
}

const mapDispatchToProps = {
  updateDimentions,
};

export default connect(null, mapDispatchToProps)(SizeDetector);
