import React, { useMemo } from 'react';
import * as THREE from 'three';
import './marker.scss';
import { HotspotSingle } from '../hotspots';

const Line = (props) => {
  const [x1, y1, z1] = props.source;
  const source = useMemo(() => new THREE.Vector3(x1, y1, z1), [x1, y1, z1]);

  // const source = new THREE.Vector3(x1, y1, z1)
  const [x2, y2, z2] = props.target;
  const target = useMemo(() => new THREE.Vector3(x2, y2, z2), [x2, y2, z2]);
  const lineGeometry = useMemo(() => {
    const line = new THREE.BufferGeometry().setFromPoints([source, target]);
    return line;
  }, [source, target]);
  return (
    <line geometry={lineGeometry}>
      <lineBasicMaterial attach="material" color="white" linewidth={1} />
    </line>
  );
};

export const Point = (props) => {
  const { visible } = props.aerial.coords;
  if (!visible) {
    return null;
  }
  const { bubbleCoords } = props.aerial;
  const { x, y, z } = bubbleCoords;
  return (
    <group>
      <mesh position={props.position}>
        <circleBufferGeometry
          attach="geometry"
          args={[0.05 * props.scale, 16]}
        />
        <meshBasicMaterial attach="material" color="white" />
      </mesh>
      <mesh position={props.position}>
        <ringBufferGeometry
          attach="geometry"
          args={[0.1 * props.scale, 0.12 * props.scale, 16]}
        />
        <meshBasicMaterial attach="material" color="white" />
      </mesh>
      <Line source={props.position} target={[x, y, z]} />
      <HotspotSingle
        tour={props.tour}
        position={[x, y, z]}
        type={'Scene'}
        scene={props.aerial}
        visited={false}
        onClick={() => props.onClickBubble(props.aerial)}
        scope={props.scope}
        history={props.history}
        hotspotHistory={props.hotspotHistory}
      />
    </group>
  );
};
