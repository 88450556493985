import React, { useState } from 'react';
import featherSearch from './feather-search.png';
import buttonSearch from './button-search.png';
import './style.scss';

function InputSearch({ onSubmitSearch }) {
  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    event.preventDefault();
    setText(event.target.value);
  };

  const onSearch = () => {
    onSubmitSearch(text);
  };

  const handleEnterKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmitSearch(text);
    }
  };

  return (
    <div className="search-form" onKeyDown={handleEnterKeyDown}>
      <label className="search-form-label">
        <img src={featherSearch} alt="feather search" />
      </label>
      <input
        type="text"
        className="search-form-input"
        placeholder="Search for..."
        value={text}
        onChange={handleInputChange}
      />
      <button type="button" className="search-form-btn" onClick={onSearch}>
        <img src={buttonSearch} alt="button searc" />
      </button>
    </div>
  );
}

export default InputSearch;
