import React, { useMemo } from 'react';
import { ERROR_MESSAGES } from './error.const';

function TourError(props) {
  const { code } = props.data;
  const errorMsg = useMemo(
    () => ERROR_MESSAGES[code] || ERROR_MESSAGES.GENERAL,
    [code]
  );

  return (
    <div
      className="NotFound"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
          fontWeight: '300',
          fontSize: '2em',
          color: '#484848',
        }}
      >
        {errorMsg}
      </h1>
    </div>
  );
}

export default TourError;
