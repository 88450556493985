import React, { useMemo, useState } from 'react';

import './aqua-hotspot-frame.scss';

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');

  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((hexChar) => hexChar + hexChar)
      .join('');
  }

  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r}, ${g}, ${b}`;
}

export default function AquaHotspotFrame({
  configs,
  isPrimeStyle,
  previewImgUrl,
  hotspot,
  onClick,
}) {
  const configsData = useMemo(() => JSON.parse(configs), [configs]);
  const {
    title,
    subtitle,
    subImage,
    titleFontSize,
    subtitleFontSize,
    titleColor = 'white',
    subtitleColor = 'white',
    backgroundBlur,
    titleBold,
    subtitleBold,
    bgColor = '#1C58A2',
    borderWidth = 1,
    borderType,
    borderColor,
  } = configsData;

  const [isShowPreviewImg, setIsShowPreviewImg] = useState(false);

  const paddingAtt = useMemo(() => {
    if (title && !subtitle && !subImage) {
      return { pdY: 15, pdX: 15 };
    }
    return { pdY: 10, pdX: 15 };
  }, [title, subtitle, subImage]);

  const rgbBgColor = hexToRgb(bgColor);

  const bgOpacity = useMemo(
    () => (title && !subtitle ? 1 : 0.9),
    [title, subtitle]
  );

  return (
    <div
      className="aquaHotspotFrame"
      style={{
        backgroundColor: `rgba(${rgbBgColor}, ${bgOpacity})`,
        border: `${borderWidth}px ${borderType} ${borderColor}`,
        backdropFilter: backgroundBlur ? 'blur(5px)' : '',
        borderRadius: isPrimeStyle ? 'unset' : '10px',
      }}
    >
      <div
        className="aquaHotspotFrame-container"
        style={{ padding: `${paddingAtt?.pdY}px ${paddingAtt?.pdX}px` }}
        onMouseOver={() => setIsShowPreviewImg(true)}
        onMouseLeave={() => setIsShowPreviewImg(false)}
        onClick={() => onClick(hotspot)}
      >
        {isShowPreviewImg && isPrimeStyle && (
          <div className="aquaHotspotFrame-preview-img">
            <img src={previewImgUrl} alt="scene-preview" />
          </div>
        )}
        {title && (
          <p
            className="aquaHotspotFrame-title"
            style={{
              fontSize: `${titleFontSize}px`,
              color: titleColor,
              fontWeight: titleBold ? 'bold' : '',
            }}
          >
            {title}
          </p>
        )}

        {subtitle && (
          <p
            className="aquaHotspotFrame-subtitle"
            style={{
              fontSize: `${subtitleFontSize}px`,
              color: subtitleColor,
              fontWeight: subtitleBold ? 'bold' : '',
            }}
          >
            {subtitle}
          </p>
        )}

        {subImage && (
          <div className="aquaHotspotFrame-imgC">
            <img src={subImage} alt="img-frame" />
          </div>
        )}
      </div>
    </div>
  );
}
