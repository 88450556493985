import { LngLat } from 'mapbox-gl';

export const metersBetween = (coord1, coord2) => {
  const lngLat1 = new LngLat(coord1[0], coord1[1]);
  const lngLat2 = new LngLat(coord2[0], coord2[1]);
  return lngLat1.distanceTo(lngLat2);
};

export const validCoord = ([lng, lat]) =>
  Number(lng) !== 0 && Number(lat) !== 0;

export const feetBetween = (coord1, coord2) => {
  return validCoord(coord1) && validCoord(coord2)
    ? metersBetween(coord1, coord2) / 0.3048
    : 0;
};

const FEET_PER_MILE = 5280;

const round = (num) => Math.round(num * 10) / 10;

export const getFriendlyDistance = (fts) => {
  if (typeof fts === 'number') {
    return fts <= 1000
      ? `${round(fts)} FT`
      : `${round(fts / FEET_PER_MILE)} Mi`;
  }
  return '';
};
