export const canFullscreen = () => {
  const docElm = document.documentElement;
  if (
    docElm.requestFullscreen ||
    docElm.mozRequestFullScreen ||
    docElm.webkitRequestFullScreen ||
    docElm.msRequestFullscreen
  ) {
    return true;
  }
  return false;
};

export const isFullscreen = () =>
  (document.fullscreenElement && document.fullscreenElement !== null) ||
  (document.webkitFullscreenElement &&
    document.webkitFullscreenElement !== null) ||
  (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
  (document.msFullscreenElement && document.msFullscreenElement !== null);

export const toggleFullScreen = () => {
  const docElm = document.documentElement;
  if (!isFullscreen()) {
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    } else {
      window.logMessage('cannot do full screen');
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else {
      window.logMessage('cannot exit full screen');
    }
  }
};
