// import { DEFAULT_AUTO_ROTATE_SPEED } from 'consts';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoRotate } from 'store/actions';

const IGNORESCENES = ['mika-topview', 'mika-topview-tang-22', 'topview'];

function findObjectIndex(arr, item) {
  if (!item) return 1;
  for (var i = 0; i < arr.length; i++) {
    if (arr[i].id === item.id) {
      return i; // Trả về vị trí nếu tìm thấy
    }
  }
  return -1; // Trả về -1 nếu không tìm thấy
}

function moveTime(tocDo, banKinh) {
  // Tính chu vi của đường tròn
  var chuVi = 2 * Math.PI * banKinh;

  // Tính thời gian di chuyển
  var thoiGian = chuVi / tocDo;

  return thoiGian;
}

const HandleAutoRotate = ({
  shouldRotate,
  scenes,
  currentPano,
  onChangeScene,
}) => {
  const autoPlay = useSelector((state) => state.autoPlayMode);
  const autoRotateSpeed = useSelector((state) => state.tour.autoRotateSpeed);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAutoRotate(autoPlay));
  }, [autoPlay, dispatch]);

  const isRotate = useMemo(() => {
    if (!shouldRotate || !autoPlay) {
      return false;
    } else return true;
  }, [autoPlay, shouldRotate]);

  useEffect(() => {
    if (scenes.length === 0) return;
    let intervalInstance = null;
    let currentIndex = findObjectIndex(scenes, currentPano);

    if (currentIndex === -1) return;
    const rotateSpeed = autoRotateSpeed || 3;

    const fetchData = () => {
      if (currentIndex === scenes.length - 1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }

      const currentScene = scenes[currentIndex];

      if (IGNORESCENES.includes(currentScene.id)) return;

      onChangeScene(currentScene.groupId, currentScene.id);
    };

    if (isRotate) {
      // first run:
      fetchData();

      // loop through scenes:

      clearInterval(intervalInstance);
      intervalInstance = setInterval(
        fetchData,

        moveTime(rotateSpeed, 1.7) * 1000
      );
    }

    return () => {
      clearInterval(intervalInstance);
    };
    // eslint-disable-next-line
  }, [autoRotateSpeed, autoPlay, isRotate, scenes]);

  return <></>;
};

export default HandleAutoRotate;
