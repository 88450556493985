import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import { useNavigatedRoutes } from 'common/hooks';

import Popup from '../../components/popup';
import SocialIcon from './icon';

import './style.scss';

const SocialPopup = (props) => {
  const { tour } = props;
  const [handleUpdateRoute] = useNavigatedRoutes();
  const formatEmailUrl = useMemo(() => `mailto:${tour.email}`, [tour.email]);

  return (
    <Popup closePopup={() => handleUpdateRoute()}>
      <div className="social-backdrop">
        {typeof tour === 'object' && (
          <div className="social-icons">
            {tour.websiteUrl && (
              <SocialIcon type="WEBSITE" {...props} link={tour.websiteUrl} />
            )}
            {tour.facebookUrl && (
              <SocialIcon type="FACEBOOK" {...props} link={tour.facebookUrl} />
            )}
            {tour.instagramUrl && (
              <SocialIcon
                type="INSTAGRAM"
                {...props}
                link={tour.instagramUrl}
              />
            )}
            {tour.twitterUrl && (
              <SocialIcon type="TWITTER" {...props} link={tour.twitterUrl} />
            )}
            {tour.youtubeUrl && (
              <SocialIcon type="YOUTUBE" {...props} link={tour.youtubeUrl} />
            )}
            {tour.email && (
              <SocialIcon type="EMAIL" {...props} link={formatEmailUrl} />
            )}
            {tour.pinterestUrl && (
              <SocialIcon
                type="PINTEREST"
                {...props}
                link={tour.pinterestUrl}
              />
            )}
            {tour.snapchatUrl && (
              <SocialIcon type="SNAPCHAT" {...props} link={tour.snapchatUrl} />
            )}
          </div>
        )}
      </div>
    </Popup>
  );
};

const mapStateToProps = ({ json, tour }) => ({
  textColor: tour.menuHighlightColor,
  color: (tour && tour.lightboxBodyColor) || 'white',
  customer: json.customer || {},
  tour: tour,
});

export default connect(mapStateToProps)(SocialPopup);
