import React from 'react';
import {
  AudioIcon,
  AutoRotateIcon,
  ContactFormIcon,
  ExpandIcon,
  FloorMapIcon,
  FullScreenIcon,
  GyroscopeIcon,
  HelpIcon,
  MapFloorPlanIcon,
  ShareIcon,
} from 'components/icons';
import configs from 'configs';
import { isMobile } from 'react-device-detect';
import { limitLetters } from 'utils/limitLetters';

import NotePlate from './NotePlate';

import './SchoolLayoutIcons.scss';

export default function SchoolLayoutIcons(props) {
  const {
    currentPano,
    isAutoPlay,
    color,
    toggleHelpButton,
    featuredMedia,
    isShowAudioIcon,
    canFullscreen,
    menuPosition,
    handleFullScreen,
    fullScreen,
    isShowMapFloorPlan,
    setIsActiveMap,
    isActiveMap,
    mapShowed,
    setOpenDropDown,
    openDropDown,
    handleSelectMap,
    scenes,
    floorMaps,
    switchMode,
    handleUpdateRoute,
    onSelect,
    isSchoolLayout,
    enableGyroscope,
    handleSwitchControlMode,
  } = props;

  return (
    <div
      className={`${
        isMobile ? 'icons icons-school__layout' : 'icons_right'
      }  school-layout`}
    >
      {isShowMapFloorPlan && (
        <>
          <div
            className="icon position-relative school-layout__icon-container"
            onClick={() => setIsActiveMap(!isActiveMap)}
          >
            <MapFloorPlanIcon active={isActiveMap} />
            {!isMobile && (
              <NotePlate note={'Map'} transform={{ x: -100, y: 0 }} />
            )}
          </div>
          <div
            className={`mapfloorplan_container ${isActiveMap ? 'open' : ''} ${
              isSchoolLayout ? 'mapfloorplan_container-school_layout' : ''
            }`}
          >
            {mapShowed && (
              <>
                <div className="map_header">
                  <div
                    className="map_dropdown"
                    onClick={() => setOpenDropDown(!openDropDown)}
                  >
                    <span>{limitLetters(mapShowed.image.name, 15)}</span>
                    <i className="map_arrow down"></i>
                    {openDropDown && (
                      <div
                        className={`map_dropdown_options ${
                          openDropDown ? 'show' : ''
                        }`}
                      >
                        <ul className="dropdown_items">
                          {floorMaps?.map((item, index) => (
                            <li
                              onClick={() => handleSelectMap(item)}
                              key={index}
                            >
                              {limitLetters(item.image.name, 10)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="icon">
                    <ExpandIcon />
                  </div>
                </div>
                <div className="map_image">
                  <img src={mapShowed.image.url} alt={mapShowed.image.name} />
                  {mapShowed.objects.map((viewpoint) => {
                    const currentPoint = viewpoint.id === currentPano._id;
                    const [left, top] = viewpoint.coordinates;
                    const leftInPercent = left * 100;
                    const topInPercent = top * 100;
                    const currentScene =
                      scenes.find((s) => s._id === viewpoint.id) || {};

                    return (
                      <div
                        key={viewpoint.id}
                        className={`viewpoint-container ${
                          currentPoint ? 'current-viewpoint' : 'viewpoint'
                        } `}
                        style={{
                          left: `${leftInPercent}%`,
                          top: `${topInPercent}%`,
                        }}
                        onClick={() =>
                          onSelect(currentScene.groupId, currentScene.id)
                        }
                      >
                        <p className="scene-title">{currentScene.title}</p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {isShowAudioIcon && (
        <div className="school-layout__icon-container">
          <AudioIcon activeColor={color} className="icon" />
          {!isMobile && (
            <NotePlate
              note={'Turn On/Off Sound'}
              transform={{ x: -192, y: 0 }}
            />
          )}
        </div>
      )}

      <div className="school-layout__icon-container">
        <div className="icon" onClick={() => switchMode()}>
          <AutoRotateIcon shouldRotate={isAutoPlay} activeColor={color} />
        </div>
        {!isMobile && (
          <NotePlate
            note={'Auto Play'}
            transform={{ x: -132, y: 0 }}
            arrowClass={'school-layout__note-arrow__autoplay'}
          />
        )}
      </div>

      {canFullscreen() && menuPosition !== 'bottom' && (
        <div className="school-layout__icon-container">
          <div className="icon" onClick={handleFullScreen}>
            <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
            {!isMobile && (
              <NotePlate note={'Full Screen'} transform={{ x: -140, y: 0 }} />
            )}
          </div>
        </div>
      )}

      <div
        className="icon-share icon-share-school__layout"
        onClick={() => handleUpdateRoute(configs.socialRoute)}
      >
        <div className="school-layout__icon-container">
          <ShareIcon color={color} />
          {!isMobile && (
            <NotePlate
              note={'Contact Info'}
              transform={{ x: -160, y: -5 }}
              arrowClass={'school-layout__note-arrow__contact'}
            />
          )}
        </div>
      </div>

      {enableGyroscope && (
        <div
          className="icons_icon icons_icon-school_layout-gyro"
          onClick={handleSwitchControlMode}
        >
          <GyroscopeIcon activeColor={color} />
        </div>
      )}

      <div className="school-layout__icon-container">
        <div className="icon" onClick={toggleHelpButton}>
          <HelpIcon />
          {!isMobile && (
            <NotePlate note={'Help'} transform={{ x: -100, y: 0 }} />
          )}
        </div>
      </div>

      {featuredMedia.map && (
        <div
          className="icon"
          onClick={() => handleUpdateRoute(featuredMedia.map.id)}
        >
          <FloorMapIcon />
        </div>
      )}

      {featuredMedia.contact && (
        <div
          className="icon"
          onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
        >
          <ContactFormIcon />
        </div>
      )}
    </div>
  );
}
