import React from 'react';

function NotFound() {
  return (
    <div
      className="NotFound"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
          fontWeight: '300',
          fontSize: '2em',
          color: '#484848',
        }}
      >
        Sorry! The Tour you are looking for is not found.
      </h1>
    </div>
  );
}

export default NotFound;
