import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import {
  trackUserEngagement,
  trackUserEngagementSingle,
  trackViewOfUser,
} from 'gaTracking';
import { useSelector } from 'react-redux';

const mouse = new THREE.Vector2(0, 0);

const SceneInteractionTracker = ({ meshRef, pano }, ref) => {
  const { tour } = useSelector((state) => state);
  const userEngagementInterval = useRef(null);
  const userEngagementTimeout = useRef(null);
  const prevPointRef = useRef(null);
  const isFirst = useRef(true);
  const countDrag = useRef(0);
  const countClick = useRef(0);
  const countScroll = useRef(0);
  const { raycaster, camera } = useThree();
  const [isUserEngagement, setIsUserEngagement] = useState(false);
  const [isChange, setIsChange] = useState(0);
  const [mouseDownTime, setMouseDownTime] = useState(0);
  const [mouseUpTime, setMouseUpTime] = useState(0);
  const [debounceTimeoutDragging, setDebounceTimeoutDragging] = useState(false);
  const [debounceTimeoutClick, setDebounceTimeoutClick] = useState(false);
  const debounceTimeoutScroll = useRef(false);
  const [eventCount, setEventCount] = useState(0);

  useImperativeHandle(ref, () => ({
    handleMouseDown() {
      if (!debounceTimeoutDragging) {
        setDebounceTimeoutDragging(true);
        setTimeout(() => {
          setDebounceTimeoutDragging(false);
        }, 1000);
        const currentTime = new Date().getTime();
        setMouseDownTime(currentTime);
      }
    },

    handleMouseUp() {
      const currentTime = new Date().getTime();
      setMouseUpTime(currentTime);
      setTimeout(() => {
        setMouseUpTime(0);
      }, 50);
    },
  }));

  const getCenterLookat = (meshRef) => {
    if (!raycaster || !camera || !meshRef) {
      return null;
    }
    raycaster.setFromCamera(mouse, camera);
    const intersection = raycaster.intersectObject(meshRef);
    return intersection.length && intersection[0].point;
  };

  const saveCurrentOrientation = () => {
    const point = getCenterLookat(meshRef.current);
    return point || null;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!debounceTimeoutScroll.current) {
        debounceTimeoutScroll.current = true;
        setTimeout(() => {
          debounceTimeoutScroll.current = false;
        }, 1000);

        setEventCount((prev) => prev + 1);
        countScroll.current += 1;

        if (countScroll.current === 3 && pano && tour) {
          trackUserEngagementSingle({
            engagement_activity: 'scroll',
            tour_id: tour.id,
            scene_id: pano._id,
          });

          countScroll.current = 0;
        }
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventCount >= 1 && isFirst.current) {
      isFirst.current = false;
    }

    if (!isFirst.current) {
      setIsUserEngagement(true);
    }

    if (eventCount === 3 && pano && tour) {
      trackUserEngagement({
        tour_id: tour.id,
        scene_id: pano._id,
      });
      setEventCount(0);
    }
    // eslint-disable-next-line
  }, [eventCount]);

  useEffect(() => {
    if (isChange === 3) {
      const currentPoint = saveCurrentOrientation();
      if (currentPoint && pano) {
        trackViewOfUser({
          coordinate: currentPoint,
          scene_id: pano._id,
        });
      }
      setIsChange(0);
    }
    // eslint-disable-next-line
  }, [isChange]);

  useEffect(() => {
    if (isUserEngagement) {
      clearInterval(userEngagementInterval.current);
      clearTimeout(userEngagementTimeout.current);
      userEngagementInterval.current = setInterval(() => {
        const currentPoint = saveCurrentOrientation();
        if (currentPoint && prevPointRef.current && pano) {
          const epsilon = 0.01;
          const { x, y, z } = currentPoint;
          const { x: prevX, y: prevY, z: prevZ } = prevPointRef.current;
          if (
            Math.abs(x - prevX) < epsilon &&
            Math.abs(y - prevY) < epsilon &&
            Math.abs(z - prevZ) < epsilon
          ) {
            setIsChange((prev) => prev + 1);
          } else {
            prevPointRef.current = currentPoint;
            setIsChange(0);
          }
        } else if (currentPoint) {
          prevPointRef.current = currentPoint;
        }
      }, 900);

      userEngagementTimeout.current = setTimeout(() => {
        clearInterval(userEngagementInterval.current);
      }, 30000);
    }
    setIsUserEngagement(false);
    // eslint-disable-next-line
  }, [isUserEngagement]);

  useEffect(() => {
    const elapsedTime = mouseUpTime - mouseDownTime;
    if (elapsedTime >= 500) {
      countDrag.current++;
      setEventCount((prev) => prev + 1);
      if (countDrag.current === 3 && pano && tour) {
        trackUserEngagementSingle({
          engagement_activity: 'drag',
          tour_id: tour.id,
          scene_id: pano._id,
        });
        countDrag.current = 0;
      }
    } else if (mouseUpTime !== 0 && elapsedTime <= 300) {
      if (!debounceTimeoutClick) {
        setDebounceTimeoutClick(true);
        setTimeout(() => {
          setDebounceTimeoutClick(false);
        }, 1000);

        countClick.current++;
        setEventCount((prev) => prev + 1);

        if (countClick.current === 3 && pano && tour) {
          trackUserEngagementSingle({
            engagement_activity: 'click',
            tour_id: tour.id,
            scene_id: pano._id,
          });
          countClick.current = 0;
        }
      }
    }

    // eslint-disable-next-line
  }, [mouseDownTime, mouseUpTime]);

  return <></>;
};

export default forwardRef(SceneInteractionTracker);
