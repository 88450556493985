import React from 'react';

const IconArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="20"
      height="20"
      stroke="#fff"
      fill="#fff"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      enableBackground="new 0 0 1000 1000"
      space="preserve"
    >
      <metadata>
        {' '}
        Svg Vector Icons : http://www.onlinewebfonts.com/icon{' '}
      </metadata>
      <g>
        <path d="M480.4,781C480.4,781,480.4,781,480.4,781c0.6,0.3,1.1,0.6,1.7,0.9c0,0,0,0,0.1,0c0.6,0.3,1.1,0.5,1.7,0.8c0,0,0.1,0,0.1,0.1c0.5,0.2,1.1,0.5,1.7,0.7c0.1,0,0.1,0.1,0.2,0.1c0.5,0.2,1.1,0.4,1.6,0.6c0.1,0,0.2,0.1,0.3,0.1c0.5,0.2,1.1,0.3,1.6,0.5c0.1,0,0.2,0.1,0.4,0.1c0.5,0.1,1,0.2,1.5,0.4c0.2,0,0.3,0.1,0.5,0.1c0.5,0.1,1,0.2,1.5,0.3c0.2,0,0.4,0.1,0.5,0.1c0.5,0.1,1,0.1,1.5,0.2c0.2,0,0.4,0.1,0.6,0.1c0.5,0.1,1,0.1,1.5,0.1c0.2,0,0.4,0,0.6,0c0.7,0,1.4,0.1,2.1,0.1c0.7,0,1.4,0,2.1-0.1c0.2,0,0.4,0,0.6,0c0.5,0,1-0.1,1.5-0.1c0.2,0,0.4-0.1,0.6-0.1c0.5-0.1,1-0.1,1.5-0.2c0.2,0,0.4-0.1,0.5-0.1c0.5-0.1,1-0.2,1.5-0.3c0.2,0,0.3-0.1,0.5-0.1c0.5-0.1,1-0.2,1.5-0.4c0.1,0,0.2-0.1,0.4-0.1c0.5-0.1,1.1-0.3,1.6-0.5c0.1,0,0.2-0.1,0.3-0.1c0.5-0.2,1.1-0.4,1.6-0.6c0.1,0,0.1-0.1,0.2-0.1c0.6-0.2,1.1-0.4,1.7-0.7c0.1,0,0.1,0,0.1-0.1c0.6-0.2,1.1-0.5,1.7-0.8c0,0,0,0,0.1,0c0.6-0.3,1.1-0.6,1.7-0.9c0,0,0,0,0,0c3.5-1.9,6.7-4.3,9.4-7.1l0,0l449-490.2c7.4-7.4,12-17.6,12-28.9c0-22.6-18.3-40.8-40.8-40.8c-11.3,0-21.5,4.6-28.9,12L500,685.4L79.7,225.9c-7.4-7.4-17.6-12-28.9-12C28.3,214,10,232.3,10,254.8c0,11.3,4.6,21.5,12,28.9l449,490.2l0,0l0,0C473.7,776.7,476.9,779.1,480.4,781z" />
      </g>
    </svg>
  );
};

export default IconArrowDown;
