export const MapFloorPlanIcon = ({ active }) => {
  return active ? (
    <svg
      height="14px"
      version="1.1"
      viewBox="-4 -4 22 22"
      width="14px"
      fill="#fff"
    >
      <g
        fill="#fff"
        fillRule="evenodd"
        id="Page-1"
        stroke="#fff"
        strokeWidth="1"
      >
        <g id="Core" transform="translate(-341.000000, -89.000000)">
          <g id="close" transform="translate(341.000000, 89.000000)">
            <path
              d="M14,1.4 L12.6,0 L7,5.6 L1.4,0 L0,1.4 L5.6,7 L0,12.6 L1.4,14 L7,8.4 L12.6,14 L14,12.6 L8.4,7 L14,1.4 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff">
      <rect width="24" height="24" stroke="none" opacity="0" />

      <g transform="matrix(0.95 0 0 0.95 12 12)">
        <path
          transform=" translate(-12, -11.5)"
          d="M 12 1 C 8.686 1 6 3.686 6 7 C 6 11.286 12 18 12 18 C 12 18 18 11.286 18 7 C 18 3.686 15.314 1 12 1 z M 12 4.8574219 C 13.184 4.8574219 14.142578 5.816 14.142578 7 C 14.142578 8.183 13.183 9.1425781 12 9.1425781 C 10.817 9.1425781 9.8574219 8.184 9.8574219 7 C 9.8574219 5.816 10.816 4.8574219 12 4.8574219 z M 4.8007812 15 L 2 22 L 22 22 L 19.199219 15 L 16.8125 15 C 16.3275 15.731 15.840578 16.408 15.392578 17 L 17.847656 17 L 19.046875 20 L 4.953125 20 L 6.1523438 17 L 8.6074219 17 C 8.1594219 16.408 7.6725 15.731 7.1875 15 L 4.8007812 15 z"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};
