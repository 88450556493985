export const sceneDayTime = {
  tongThe01: 'tong-the-toan-canh-01',
  tongThe02: 'tong-the-toan-canh-02',
  hoBoi: 'ho-boi',
  congVao: 'cong-vao',
  bbq: 'bbq-riverside',
  quangTruong: 'quang-truong',
  suKien: 'su-kien',
};

export const sceneNightTime = {
  tongThe01: 'tong-the-toan-canh-01---ban-o8em',
  tongThe02: 'tong-the-toan-canh-02---ban-wcem',
  hoBoi: 'ho-boi---ban-3fem',
  congVao: 'cong-vao-ban-8hem',
  bbq: 'bbq-riverside-ban-kgem',
  quangTruong: 'quang-truong-ban-q0em',
  suKien: 'su-kien-ban-2rem',
};

export const sceneDay2Night = {
  [sceneDayTime.tongThe01]: sceneNightTime.tongThe01,
  [sceneDayTime.tongThe02]: sceneNightTime.tongThe02,
  [sceneDayTime.hoBoi]: sceneNightTime.hoBoi,
  [sceneDayTime.congVao]: sceneNightTime.congVao,
  [sceneDayTime.bbq]: sceneNightTime.bbq,
  [sceneDayTime.quangTruong]: sceneNightTime.quangTruong,
  [sceneDayTime.suKien]: sceneNightTime.suKien,
};

export const sceneNight2Day = {
  [sceneNightTime.tongThe01]: sceneDayTime.tongThe01,
  [sceneNightTime.tongThe02]: sceneDayTime.tongThe02,
  [sceneNightTime.hoBoi]: sceneDayTime.hoBoi,
  [sceneNightTime.congVao]: sceneDayTime.congVao,
  [sceneNightTime.bbq]: sceneDayTime.bbq,
  [sceneNightTime.quangTruong]: sceneDayTime.quangTruong,
  [sceneNightTime.suKien]: sceneDayTime.suKien,
};

const objHasValue = (obj, val) => {
  return Object.values(obj).indexOf(val) !== -1;
};

export const isDayScene = (sceneId) => {
  return objHasValue(sceneDayTime, sceneId);
};

export const isNightScene = (sceneId) => {
  return objHasValue(sceneNightTime, sceneId);
};

export const getOppositeScene = (sceneId) => {
  if (isDayScene(sceneId)) {
    return sceneDay2Night[sceneId];
  } else if (isNightScene(sceneId)) {
    return sceneNight2Day[sceneId];
  } else {
    return null;
  }
};

export const sceneDayNightList = [
  ...Object.values(sceneDayTime),
  ...Object.values(sceneNightTime),
];

export const isDayOrNight = (sceneId) => {
  return sceneDayNightList.indexOf(sceneId) !== -1;
};
