import React, { useMemo } from 'react';

import './style.scss';

const MenuItem = ({
  scenes,
  sceneGroup,
  onSelect,
  selectedCard,
  handleSelect,
  activeColor,
}) => {
  const { defaultSceneId, title, _id } = sceneGroup;

  const scenesInGroup = useMemo(() => {
    return scenes.filter((scene) =>
      sceneGroup.scenes.some((groupScene) => groupScene.id === scene._id)
    );
  }, [sceneGroup, scenes]);

  const defaultScene = useMemo(() => {
    return defaultSceneId
      ? scenesInGroup.find((scene) => scene._id === defaultSceneId)
      : scenesInGroup[0];
  }, [defaultSceneId, scenesInGroup]);

  const handleSwitchPano = () => {
    onSelect(sceneGroup.id, defaultScene.id);
    handleSelect();
  };

  const isSelected = useMemo(() => selectedCard === _id, [_id, selectedCard]);

  return (
    <div
      className="menu-card"
      onClick={handleSwitchPano}
      style={{ border: isSelected ? `2px solid ${activeColor}` : '' }}
    >
      <div className="menu-image">
        <img src={defaultScene.previewImgUrl} alt={defaultScene.title} />
      </div>
      <div className="menu-title">{title}</div>
    </div>
  );
};

export default MenuItem;
