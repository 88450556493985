import * as THREE from 'three';
import { isAerial } from '../../consts/sceneType.const';

export const SCALE = 1.0;

const isValidCoords = (coords) =>
  !(
    typeof coords !== 'object' ||
    typeof coords[0] !== 'number' ||
    typeof coords[1] !== 'number'
  );

export const queryAerialScenes = (scenes = []) => {
  return scenes.filter(
    (scene) => isAerial(scene.type) && isValidCoords(scene.coordinates)
  );
};

export const queryMapScenes = (scenes = []) => {
  return scenes.filter(
    (scene) => scene.showOnMap && isValidCoords(scene.coordinates)
  );
};

export const calc3DLocation = (center, point, ratio, boxWidth) => {
  if (isValidCoords(point)) {
    return {
      x: 0,
      y: 0,
      visible: false,
    };
  }
  const coords = {
    x: (point.lng - center.lng) / ratio,
    y: (point.lat - center.lat) / ratio,
  };
  if (
    Math.abs(coords.x) >= boxWidth / 2 ||
    Math.abs(coords.y) >= boxWidth / 2
  ) {
    coords.visible = false;
  } else {
    coords.visible = true;
  }
  return coords;
};

const BUBBLE_WIDTH = 1 * SCALE;
const BUBBLE_OFFSET = BUBBLE_WIDTH * 2;

export const distance2d = (a, b) => {
  const a1 = new THREE.Vector2(a.x, a.y);
  const b1 = new THREE.Vector2(b.x, b.y);
  return a1.distanceTo(b1);
};

export const getBubbleDefaultPosition = (aerials = []) => {
  return aerials.map((aerial) => ({
    ...aerial,
    bubbleCoords: {
      x: aerial.coords.x,
      y: aerial.coords.y,
      z: 1.6,
    },
  }));
};

export const getCenter = (aerials = []) => {
  const totals = aerials.reduce(
    (acc, aerial) => {
      acc.x += aerial.bubbleCoords.x;
      acc.y += aerial.bubbleCoords.y;
      return acc;
    },
    { x: 0, y: 0 }
  );
  return {
    x: totals.x / aerials.length,
    y: totals.y / aerials.length,
  };
};

function getRandomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

export const separateAerials = (aerials = [], centerCoord = null) => {
  const center = centerCoord || getCenter(aerials);
  aerials.forEach((aerial) => {
    const { x, y } = aerial.bubbleCoords;
    const vecCenter = new THREE.Vector2(x - center.x, y - center.y);
    if (vecCenter.x === 0 && vecCenter.y === 0) {
      vecCenter.x = getRandomNumber(0, 1);
      vecCenter.y = getRandomNumber(0, 1);
    }
    window.logMessage(vecCenter.x, vecCenter.y);
    vecCenter.normalize();
    window.logMessage(vecCenter.x, vecCenter.y);
    aerial.bubbleCoords = {
      x: aerial.bubbleCoords.x + vecCenter.x / 5,
      y: aerial.bubbleCoords.y + vecCenter.y / 5,
      z: aerial.bubbleCoords.z,
    };
    window.logMessage(aerial.id, aerial.bubbleCoords.x, aerial.bubbleCoords.y);
  });
};

export const calcBubblePosition = (aerials = [], times = 0) => {
  if (times >= 10) {
    window.logMessage('too much loop, stop!');
    return aerials;
  }
  window.logMessage('calcBubblePosition.loop', times);
  const collapsedAerials = {};
  const objectAerials = {};
  let haveCollapsed = false;
  aerials.forEach((aerial, index) => {
    objectAerials[aerial.id] = aerial;
    if (typeof collapsedAerials[aerial.id] === 'undefined') {
      collapsedAerials[aerial.id] = {};
    }
    aerials.forEach((aerial2, jindex) => {
      if (typeof collapsedAerials[aerial2.id] === 'undefined') {
        collapsedAerials[aerial2.id] = {};
      }
      if (index !== jindex) {
        if (
          distance2d(aerial.bubbleCoords, aerial2.bubbleCoords) < BUBBLE_OFFSET
        ) {
          collapsedAerials[aerial.id][aerial.id] = true;
          collapsedAerials[aerial.id][aerial2.id] = true;
          collapsedAerials[aerial2.id][aerial.id] = true;
          collapsedAerials[aerial2.id][aerial2.id] = true;
          haveCollapsed = true;
        }
      }
    });
  });
  if (haveCollapsed) {
    window.logMessage('collapsedAerials', collapsedAerials);
    const { grouped, uniques } = getGroupAerials(collapsedAerials);
    window.logMessage('grouped', grouped);
    Object.keys(grouped).forEach((key) => {
      const currGroup = grouped[key].map((g) => objectAerials[g]);
      const currCenter = { ...objectAerials[key].bubbleCoords };
      separateAerials(currGroup, currCenter);
    });
    window.logMessage('uniques', uniques);
    Object.values(uniques).forEach((unique) => {
      separateAerials(unique.map((u) => objectAerials[u]));
    });
    return calcBubblePosition(aerials, times + 1);
  }
  return aerials;
};

const getGroupAerials = (aerials = {}) => {
  const uniques = {};
  Object.keys(aerials).forEach((key) => {
    const currA = Object.keys(aerials[key]);
    window.logMessage('currA', [...currA]);
    window.logMessage('curr uniques', Object.values(uniques));
    if (currA.length && !isArr1ChildOfArr2(currA, Object.values(uniques))) {
      uniques[key] = currA;
    }
  });
  window.logMessage('uniques', { ...uniques });
  const grouped = {};
  const toGroup = {};
  Object.keys(uniques).forEach((k1) => {
    Object.keys(uniques).forEach((k2) => {
      if (k1 !== k2) {
        const childArrId = getChildGroup(uniques[k1], uniques[k2]);
        if (childArrId !== 0) {
          const childId = childArrId === 1 ? k1 : k2;
          const parentId = childArrId === 2 ? k1 : k2;
          delete grouped[childId];
          delete toGroup[childId];
          if (!grouped[parentId]) {
            grouped[parentId] = uniques[parentId].filter(
              (k3) => k3 !== parentId
            );
          }
          toGroup[parentId] = parentId;
        }
      }
    });
  });
  Object.keys(toGroup).forEach((key) => {
    if (uniques[key]) {
      delete uniques[key];
    }
  });
  window.logMessage('toGroup', { ...toGroup });
  return { uniques, grouped };
};

const isArr1ChildOfArr2 = (arr = [], arr2 = []) =>
  !!arr2.find((aChild) => isArr1EqualsArr2(arr, aChild));

const isArr1InArr2 = (arr = [], arr2 = []) =>
  arr.every((val) => arr2.indexOf(val) >= 0);

const isArr1EqualsArr2 = (arr = [], arr2 = []) =>
  arr.every((val) => arr2.indexOf(val) >= 0) && arr.length === arr2.length;

const getChildGroup = (arr = [], arr2 = []) => {
  if (isArr1InArr2(arr, arr2)) {
    return 1;
  }
  if (isArr1InArr2(arr2, arr)) {
    return 2;
  }
  return 0;
};

window.getChildGroup = getChildGroup;
