import React, { useMemo } from 'react';

import { defaultIconBgColor } from 'consts';
import { mixColor } from 'utils/colorHelper';

const SceneIcon = ({ colorChecked, active }) => {
  const bgColor = useMemo(
    () => (active ? defaultIconBgColor : mixColor(colorChecked, '#000')),
    [active, colorChecked]
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        style={{
          fill: bgColor,
          stroke: bgColor,
          opacity: 0.8,
        }}
      >
        <ellipse
          style={{ fill: bgColor }}
          cx="11"
          cy="11"
          rx="10.5"
          ry="10.5"
        />
      </g>
      <g transform="translate(5.672 3.284)">
        <path
          style={{ fill: '#fff' }}
          d="M78.514,11.678c.84.288,1.581.471,2.646.8C80.751,17.647,75.861,15.594,78.514,11.678Zm4.366-4.75c-.055-1.553-.329-3.589-2.507-3.27-1.021.266-1.776,1.388-2.131,3.323a9.292,9.292,0,0,0,.148,3.452c.21.631.139.592.364.712.873.2,1.738.414,2.618.637C82.267,11.15,83,7.8,82.88,6.928Zm-6.723-.119a9.3,9.3,0,0,0,.148-3.452C75.951,1.421,75.2.3,74.175.033,72-.286,71.723,1.751,71.668,3.3c-.122.869.613,4.222,1.507,4.853.88-.222,1.745-.44,2.618-.637C76.018,7.4,75.947,7.44,76.157,6.809Zm-2.77,2.043c.409,5.171,5.3,3.117,2.646-.8C75.194,8.342,74.452,8.525,73.387,8.852Z"
          transform="translate(-71.655 0)"
        />
      </g>
    </svg>
  );
};

export default SceneIcon;
