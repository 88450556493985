import React, { useMemo, useState } from 'react';

import './TopviewSubmenu.scss';
import { mikaSceneList, sceneList } from './scene.helper';
import PreviewSceneImage from './PreviewSceneImage';

export default function TopviewSubmenu({ scenes, currentPano, onSelect , sceneDetail}) {
  // const isMobile = useMemo(() => window.innerWidth < 768, []);
  const [hover, setHover] = useState(null);
  const subTopviewScenes = useMemo(
    () =>
      scenes
        .filter(
          (scene) => sceneDetail.id === 'topview' ? (scene.id !== 'topview' && sceneList.includes(scene.id)) : (scene.id !== 'mika-topview' && mikaSceneList.includes(scene.id)) 
        )
        .sort((a, b) => {
          const aTitle = typeof a === 'object' ? a.title : a;
          const bTitle = typeof b === 'object' ? b.title : b;
          return aTitle.localeCompare(bTitle);
        }),
    [sceneDetail.id, scenes]
  );

  return (
    <div className={`topview-scenes`} onClick={(e) => e.stopPropagation()}>
      {subTopviewScenes.length &&
        subTopviewScenes.map((scene, index) => (
          <div
            className="topview-item"
            key={index}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(null)}
            onClick={() => {
              onSelect(scene.groupId, scene.id);
              // setOpenMobileMenu && setOpenMobileMenu();
            }}
          >
            <p
              className={`topview-title ${
                currentPano?.id === scene.id ? 'current-scene' : ''
              }`}
            >
              <span className="horizontal-line"></span>
              {scene.title}
            </p>

            <div className="topview-preview-container">
              <PreviewSceneImage
                subTopviewScenes={subTopviewScenes}
                indexHover={hover}
              />
            </div>
          </div>
        ))}
    </div>
  );
}
