import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { CONTROLS } from 'consts';
import { isMobile } from 'deviceDetect';

import { DeviceOrientationControls } from './deviceOrientationControls';
import OrbitControls from './orbitControls';

const isMobileDevice = isMobile();

const Controls = (props) => {
  const { controlMode, gyro } = useSelector((state) => state);

  const [isDevice, setIsDevice] = useState(
    () => controlMode === CONTROLS.DEVICE && isMobileDevice && gyro
  );

  useEffect(() => {
    setIsDevice(controlMode === CONTROLS.DEVICE);
  }, [controlMode]);

  if (isDevice) return <DeviceOrientationControls {...props} />;

  return <OrbitControls {...props} />;
};

export default Controls;
