import React, { useEffect, useMemo, useState } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
// import { isMobile } from 'react-device-detect';
// import NotePlate from 'components/MenuForDesktop/NotePlate';

import AquaMenuItem from './AquaMenuItem';
import {
  AquaMapfloorplanIconV2,
  AquaRightArrow,
  AquaGreenParkIcon,
  AquaHouse,
  AquaVilla,
  AquaSceneMenuIcon,
  AquaLargeMapfloorplanIcon,
} from 'components/icons/AquaIcons';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './style.scss';
import HoveringPlate from 'components/HoveringPlate';

const Icons = ({ iconOrder, isActive }) => {
  const iconComponents = [
    <AquaMapfloorplanIconV2 isActive={isActive} />,
    <AquaGreenParkIcon isActive={isActive} />,
    <AquaHouse isActive={isActive} />,
    <AquaVilla isActive={isActive} />,
  ];

  return <>{iconComponents[iconOrder]}</>;
};

const AACorporationLayout = ({
  desktop,
  mobile,
  scenes,
  groups,
  onSelect,
  openMenu,
  handleOpenMenu,
  // activeColor,
  currentPano,
  menuList,
  // isSchoolLayout,
  tourLogo,
}) => {
  // const [selectedCard, setSelectedCard] = useState(null);
  const [hoverMenuItem, setHoverMenuItem] = useState(null);
  const [hoverScenes, setHoverScenes] = useState([]);
  const [openMobileMenu, setOpenMobileMenu] = useState(true);
  const [openRightMenu, setOpenRightMenu] = useState(false);

  const { hasLogo } = useSelector((state) => ({
    logoURL: state.logoURL,
    hasLogo: state.hasLogo,
  }));

  const enableBranding = useSelector(
    ({ subscriptionPlan }) => subscriptionPlan.enableBranding
  );

  const menuScenes = useMemo(() => {
    return menuList.flatMap((item) => item.scenes);
  }, [menuList]);

  useEffect(() => {
    if (hoverMenuItem === null) {
      setHoverScenes([]);
      return;
    }

    const menu = menuList[hoverMenuItem];
    setHoverScenes([...menu.scenes]);
  }, [hoverMenuItem, menuList, menuScenes]);

  const isMobile = useMemo(() => window.innerWidth < 1024, []);
  const isIpad = useMemo(
    () => window.innerWidth <= 1023 && window.innerWidth > 767,
    []
  );

  const currentMenu = useMemo(() => {
    if (currentPano) {
      return menuList.find((item) =>
        item.scenes.some((sce) => sce._id === currentPano._id)
      );
    }
    return null;
  }, [currentPano, menuList]);

  const handleOpenMenuInMobile = () => {
    if (hoverMenuItem !== null) return;
    setHoverMenuItem(currentMenu.order);
  };

  const rightGroupMenu = useMemo(
    () =>
      groups.find((gr) =>
        gr.scenes.some((scene) => scene.id === currentPano?._id)
      ),
    [groups, currentPano]
  );

  const getParseSceneId = (sceneId) => {
    const foundScene = scenes.find((sce) => sce._id === sceneId);
    return foundScene.id;
  };

  return (
    <>
      {!isMobile ? (
        <div className="aqua-menu-wrapper">
          <div
            className={`tour-menu aqua-menu-gallery ${
              desktop ? 'desktop' : ''
            } ${mobile ? 'mobile' : ''}  ${
              openMenu ? 'menu-open' : 'menu-close'
            }`}
          >
            {enableBranding && hasLogo && (
              <div className="school-layout__tour-logo">
                <HoveringPlate textHover={'Trang chủ'}>
                  <img alt="tour-logo" src={tourLogo}></img>
                </HoveringPlate>
              </div>
            )}

            <div
              className={`menu-items menu-items__school-layout ${
                mobile ? 'menu-nonwrap' : ''
              }`}
            >
              <div>
                {menuList.map((item) => (
                  <div
                    key={item.order}
                    className="menu-items-aqua"
                    onMouseOver={() => setHoverMenuItem(item.order)}
                    onMouseLeave={() => setHoverMenuItem(null)}
                  >
                    <div
                      className="menu-items-aqua__container"
                      onClick={() =>
                        onSelect(item.scenes[0].groupId, item.scenes[0].id)
                      }
                    >
                      <p
                        className={`menu-items-aqua__title ${
                          hoverMenuItem === item.order ? 'hovering' : ''
                        }`}
                      >
                        {item.name}
                      </p>
                      <Icons
                        iconOrder={item.order}
                        isActive={hoverMenuItem === item.order}
                      />
                    </div>

                    <div
                      className={`menu-items-aqua__scenes ${
                        hoverMenuItem === item.order ? 'hovering' : ''
                      }`}
                    >
                      {hoverScenes.map((scene) => (
                        <React.Fragment key={scene.order}>
                          <AquaMenuItem
                            scene={scene}
                            scenes={scenes}
                            onSelect={onSelect}
                            currentPano={currentPano}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="menu-items-aqua__links">
                <div className="menu-items-aqua__links-container">
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`menu-items-aqua__weather ${
                !openMenu ? 'translateX' : ''
              }`}
            >
              <p
                className={`menu-items-aqua__weather-title ${
                  !openMenu ? 'changeColor' : ''
                }`}
              >
                TP. Hồ Chí Minh:<i className="fa fa-cloud-moon"></i>
                30°C/87°F
              </p>
            </div>

            <div
              className="menu-toggle menu-toggle-school__layout-dtwood"
              onClick={handleOpenMenu}
            >
              <label
                className="menu-button-container school-layout__icon-container"
                htmlFor="menu-toggle"
              >
                <div className={`menu-button ${openMenu ? 'menu-open' : ''}`}>
                  <div className="menu-bar"></div>
                  <div className="menu-bar"></div>
                  <div className="menu-bar"></div>
                </div>
              </label>
            </div>
          </div>

          <div className="aqua-menu-right__menu">
            <div
              className="aqua-menu-right__menu-icon"
              onMouseOver={() => setOpenRightMenu(true)}
              onMouseLeave={() => setOpenRightMenu(false)}
            >
              <AquaSceneMenuIcon />
              {rightGroupMenu && (
                <div
                  className={`aqua-menu-right__menu-icon-scenes ${
                    openRightMenu ? 'hovering' : ''
                  }`}
                >
                  <div className="aqua-menu-right__menu-icon-scenes__header">
                    <p className="aqua-menu-right__menu-icon-scenes__header-title">
                      {rightGroupMenu.title}
                    </p>
                  </div>
                  {rightGroupMenu.scenes.map((scene, index) => (
                    <div
                      className="aqua-menu-right__menu-icon-scenes__item"
                      key={index}
                      onClick={() =>
                        onSelect(rightGroupMenu.id, getParseSceneId(scene.id))
                      }
                    >
                      <p className="aqua-menu-right__menu-icon-scenes__item-title">
                        {scene.title}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="aqua-menu-right__menu-icon">
              <AquaLargeMapfloorplanIcon />
            </div>
          </div>
        </div>
      ) : (
        <div className={`aquaMenu-mobile ${!openMobileMenu ? 'openMenu' : ''}`}>
          <div className="aquaMenu-mobile-container__top">
            <div className="aquaMenu-mobile-left__container">
              <p
                className={`aquaMenu-mobile-weather__title ${
                  !openMenu ? 'changeColor' : ''
                }`}
              >
                TP. Hồ Chí Minh:<i className="fa fa-cloud-moon"></i>
                30°C/87°F
              </p>
              <div
                className={`menu-toggle ${openMobileMenu ? 'background' : ''}`}
                onClick={() => {
                  setOpenMobileMenu(!openMobileMenu);
                  if (openMobileMenu) handleOpenMenuInMobile();
                }}
              >
                <label
                  className="menu-button-container school-layout__icon-container"
                  htmlFor="menu-toggle"
                >
                  <div
                    className={`menu-button ${
                      openMobileMenu ? 'menu-open' : ''
                    }`}
                  >
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                    <div className="menu-bar"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div
            className={`aquaMenu-mobile-container__bottom ${
              openMobileMenu ? 'openMenu' : ''
            }`}
          >
            <div
              className={`menu-items menu-items__school-layout ${
                mobile ? 'menu-nonwrap' : ''
              }`}
            >
              <div>
                {menuList.map((item) => (
                  <div
                    key={item.order}
                    className="menu-items-aqua"
                    onClick={() => {
                      if (
                        hoverMenuItem !== null &&
                        hoverMenuItem === item.order
                      ) {
                        setHoverMenuItem(null);
                      } else {
                        setHoverMenuItem(item.order);
                      }
                      // onSelect(item.scenes[0].groupId, item.scenes[0].id);
                    }}
                  >
                    <div
                      className={`menu-items-aqua__container ${
                        hoverMenuItem === item.order ? 'background' : ''
                      }`}
                    >
                      <p
                        className={`menu-items-aqua__title ${
                          hoverMenuItem === item.order ? 'hovering' : ''
                        }`}
                      >
                        {item.name}
                      </p>
                      <Icons
                        iconOrder={item.order}
                        isActive={hoverMenuItem === item.order}
                      />
                    </div>

                    <div
                      className={`menu-items-aqua__scenes ${
                        hoverMenuItem === item.order ? 'hovering' : ''
                      }`}
                    >
                      {hoverScenes.map((scene) => (
                        <React.Fragment key={scene.order}>
                          <AquaMenuItem
                            scene={scene}
                            scenes={scenes}
                            onSelect={onSelect}
                            currentPano={currentPano}
                            setOpenMobileMenu={() => setOpenMobileMenu(true)}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="menu-items-aqua__links">
                <div className="menu-items-aqua__links-container">
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                  <button
                    className="menu-items-aqua__links-item"
                    href="#"
                    target="_blank"
                  >
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="aqua-navInfo">
            <p className="aqua-navInfo-title">
              {currentMenu && currentMenu.name}
            </p>
            <div className="aqua-navInfo-arrow">
              <AquaRightArrow />
            </div>

            <p className="aqua-navInfo-title">
              {currentPano && currentPano.title}
            </p>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="aqua-navInfo">
          <p className="aqua-navInfo-title">
            {currentMenu && currentMenu.name}
          </p>
          <div className="aqua-navInfo-arrow">
            <AquaRightArrow />
          </div>

          <p className="aqua-navInfo-title">
            {currentPano && currentPano.title}
          </p>
        </div>
      )}

      {isIpad && (
        <div className="aquaMenu-ipad">
          <p className={`aquaMenu-ipad-weather__title`}>
            TP. Hồ Chí Minh:<i className="fa fa-cloud-moon"></i>
            30°C/87°F
          </p>
        </div>
      )}
    </>
  );
};

export default AACorporationLayout;
