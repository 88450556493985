import React, { Suspense } from 'react';

import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Provider, ReactReduxContext } from 'react-redux';
import { Router } from 'react-router-dom';

import { ensureSubscriptionData } from 'utils';
// import configs from 'configs';
import Controls from 'containers/world/controls';
import HOTSPOT_TYPE from 'consts/hotspotType.const';
import MEDIA_TYPE_AUDIO from 'consts/mediaTypeAudio';

// import TriangleBlurEffect from '../effects/triangleBlurEffect';
import WebLensflare from './lensflare';
import LineListHandler from './lineListHander';
import WorldInteract from './worldInteract';
import Anisotropy from './anisotropy';
import RenderOverride from './renderOverride';
import DeviceControlMode from './deviceControlMode';

import SceneController from './sceneController';

const DEVICE_PIXEL_RATIO = window.devicePixelRatio || [1, 2];

class World3D extends React.Component {
  state = {
    hotspots: [],
    linkedMedias: [],
    linkedScenes: [],
    showHP: false,
    interactionOn: false,
    hideFootSteps: false,
    hotspotName: null,
  };
  camera = new THREE.Camera();
  size = {
    width: 0,
    height: 0,
  };
  lastPanTime = null;
  lastPos = {
    x: 0,
    y: 0,
    z: 0,
  };
  subscriptionScope = null;

  onSelectPano = (pano) => {
    this.props.onSwitchPano(pano);
  };

  trackOnSwitchPano = (scene) => {
    const { tour } = this.props.json;
    const wTourData = { ...window.trackingTourData };
    const wSceneData = { ...window.trackingSceneData };

    if (wTourData.tour_id !== tour.id) {
      window.trackingTourData = {
        ...wTourData,
        tour_id: tour.id,
        scene_id: scene._id,
        referrer_id: 'jump_spot',
      };
      window.trackingSceneData = {
        ...wSceneData,
        tour_id: tour.id,
        scene_id: scene._id,
        referrer_id: 'tour_access',
      };
    } else {
      window.trackingSceneData = {
        ...wSceneData,
        tour_id: tour.id,
        scene_id: scene._id,
        referrer_id: 'jump_spot',
      };
    }
  };

  onSelectPanoById = (gId, sId) => {
    const scene = this.props.json.scenes.find(
      ({ id, groupId }) => id === sId && groupId === gId
    );
    window.logMessage(gId, sId, scene);
    this.onSelectPano(scene);
    this.props.setHotspotHovering(false);
    this.trackOnSwitchPano(scene);
  };

  toggleHideFootSteps = (value) => {
    this.setState({ hideFootSteps: value });
  };

  componentDidUpdate(preProps) {
    if (!this.subscriptionScope && this.props.tour) {
      this.subscriptionScope = ensureSubscriptionData(
        this.props.tour.subscriptionPlan.scopes
      );
    }
    if (
      preProps.currentPano &&
      this.props.currentPano &&
      preProps.currentPano.id !== this.props.currentPano.id
    ) {
      this.setState({ hotspots: [], showHP: false, interactionOn: false });
      this.updateHotspots();
    }
    if (!preProps.currentPano && this.props.currentPano) {
      this.updateHotspots();
    }
  }

  getViewingAngle = () => {
    const { defaultViewingAngle } = this.props.json.tour;
    if (
      defaultViewingAngle &&
      defaultViewingAngle >= 20 &&
      defaultViewingAngle <= 120
    ) {
      return defaultViewingAngle;
    }
    return 60;
  };

  updateHotspots = () => {
    const { hotspots } = this.props.currentPano;
    if (hotspots && hotspots.constructor === Array) {
      const hps = hotspots.filter(
        (hs) =>
          (hs.type === HOTSPOT_TYPE.MEDIA &&
            hs.media &&
            hs.media.type !== 'MapFloorPlan' &&
            !MEDIA_TYPE_AUDIO.includes(hs.media.type)) ||
          (hs.type === HOTSPOT_TYPE.SCENE && hs.scene)
      );
      this.setState({ hotspots: hps });
    }
  };

  queryHotspotsByType = (type) => {
    const { hotspots } = this.props.currentPano;
    if (hotspots && hotspots.constructor === Array) {
      const validHotspots = hotspots.filter(
        (hs) =>
          hs.type &&
          typeof hs.location === 'object' &&
          typeof hs.location.x === 'number' &&
          typeof hs.location.y === 'number' &&
          typeof hs.location.z === 'number'
      );
      if (type === 'scene') {
        return validHotspots.filter((hs) => hs.type === 'pano' && hs.panoId);
      }
      return validHotspots.filter((hs) => hs.type !== 'pano');
    }
    return [];
  };

  enableInteraction = () => {
    this.setState({ interactionOn: true, showHP: true });
  };

  render() {
    const { hotspots, showHP, interactionOn, hideFootSteps } = this.state;
    const {
      currentPano,
      viewMode,
      popupOpen,
      autoRotate,
      shouldRotate,
      enteredPasscode,
    } = this.props;
    const { scenes, tour } = this.props.json;

    if (!currentPano) return null;

    return (
      <div className="World-Canvas">
        <div className="Canvas-3D">
          <ReactReduxContext.Consumer>
            {({ store }) => (
              <Canvas
                camera={{
                  fov: this.getViewingAngle(),
                  zoom: 0.8,
                  near: 1,
                  far: 10000,
                }}
                dpr={DEVICE_PIXEL_RATIO}
              >
                <Anisotropy />
                <Provider store={store}>
                  <Router history={this.props.history}>
                    <ambientLight intensity={2} color={0xffffff} />

                    <Suspense fallback={null}>
                      {interactionOn && <WebLensflare pano={currentPano} />}
                    </Suspense>

                    {enteredPasscode && (
                      <Controls
                        autoRotate={
                          viewMode === 'preview' ? true : shouldRotate
                        }
                        autoRotateSpeed={
                          viewMode === 'preview' ? null : tour.autoRotateSpeed
                        }
                        enableDamping={interactionOn}
                        enable={interactionOn && !popupOpen}
                      />
                    )}

                    <Suspense fallback={null}>
                      <SceneController
                        pano={currentPano}
                        scenes={scenes}
                        introClosed={this.props.introClosed}
                        onSDLoaded={this.props.onSDLoaded}
                        onHDLoaded={this.props.onHDLoaded}
                        onSwitchScene={this.onSelectPanoById}
                        onShowHotspots={this.updateHotspots}
                        onChangeLookAt={this.enableInteraction}
                        hideFootSteps={hideFootSteps}
                        toggleHideFootSteps={this.toggleHideFootSteps}
                        tripodMarkerUrl={tour.tripodMarkerUrl}
                        getCameraParams={this.props.getCameraParams}
                        getSceneRotation={this.props.getSceneRotation}
                      />
                    </Suspense>

                    <Suspense fallback={null}>
                      <WorldInteract {...this.props.pointerEvents} />
                    </Suspense>

                    <Suspense fallback={null}>
                      <DeviceControlMode />
                    </Suspense>

                    <LineListHandler
                      showHP={showHP}
                      hotspots={viewMode === 'preview' ? [] : hotspots}
                      tour={tour}
                      autoRotate={autoRotate}
                      scope={this.subscriptionScope}
                      currentSceneCoords={currentPano.coordinates}
                      hotspotHistory={this.props.hotspotHistory}
                      onClickJumpSpot={this.onSelectPanoById}
                      toggleHideFootSteps={this.toggleHideFootSteps}
                      brandColor={this.props.tour?.menuHighlightColor}
                      setHotspotHovering={this.props.setHotspotHovering}
                      currentPano={currentPano}
                      handleApartmentPopup={this.props.handleApartmentPopup}
                    />

                    <RenderOverride />

                    {/* <TriangleBlurEffect
                      enteredPasscode={this.props.enteredPasscode}
                    /> */}
                  </Router>
                </Provider>

                {/* {configs.local && <Stats showPanel={0} />} */}
              </Canvas>
            )}
          </ReactReduxContext.Consumer>
        </div>
      </div>
    );
  }
}

export default World3D;
