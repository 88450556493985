import { DEFAULT_SCOPES_DATA, PLAN_SCOPE_KEYS } from 'consts';

export const ensureSubscriptionData = (subscriptionData) =>
  subscriptionData.reduce((preValue, currentValue) => {
    const { key, value } = currentValue;
    switch (key) {
      case PLAN_SCOPE_KEYS.SCOPE_TYPE:
        return { ...preValue, scopeType: value };
      case PLAN_SCOPE_KEYS.TOUR_COUNT:
        return { ...preValue, tour: { ...preValue.tour, value: +value } };
      case PLAN_SCOPE_KEYS.SCENE_COUNT:
        return { ...preValue, scene: { ...preValue.scene, value: +value } };
      case PLAN_SCOPE_KEYS.MEDIA_COUNT:
        return { ...preValue, media: { ...preValue.media, value: +value } };
      case PLAN_SCOPE_KEYS.USER_COUNT:
        return { ...preValue, user: { ...preValue.user, value: +value } };
      case PLAN_SCOPE_KEYS.SCENE_PER_TOUR_COUNT:
        return {
          ...preValue,
          scene: { ...preValue.scene, limitPerTour: +value },
        };
      case PLAN_SCOPE_KEYS.MEDIA_PER_TOUR_COUNT:
        return {
          ...preValue,
          media: { ...preValue.media, limitPerTour: +value },
        };
      case PLAN_SCOPE_KEYS.ENABLE_BRANDING:
        return {
          ...preValue,
          enableBranding: { ...preValue.enableBranding, value: value },
        };
      case PLAN_SCOPE_KEYS.ENABLE_ANALYTICS:
        return {
          ...preValue,
          enableAnalytics: { ...preValue.enableAnalytics, value: value },
        };
      default:
        return preValue;
    }
  }, DEFAULT_SCOPES_DATA);
