import React, { useEffect, useMemo, useRef, useState } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useSelector } from 'react-redux';

import configs from 'configs';
import { isMobile } from 'deviceDetect';

import { OrbitControls } from './OrbitControls';

window.toggleOverride = null;

let isFirstShowGuideline = true;

extend({ OrbitControls });

const isMobileDevice = isMobile();
const ROTATE_SPEED = isMobileDevice ? -0.5 : -0.25;
const ZOOM_SPEED = isMobileDevice ? 0.8 : 1;

const Controls = (props) => {
  // Three Hooks
  const { camera, gl } = useThree();
  window.camera = camera;
  const searchParams = useSelector((state) => state.searchParams);
  const autoPlayMode = useSelector((state) => state.autoPlayMode);
  const [guideMode, setGuideMode] = useState(false);

  useEffect(() => {
    if (
      searchParams['mode'] === 'no-intro' &&
      !autoPlayMode &&
      isFirstShowGuideline
    ) {
      setGuideMode(true);
    } else {
      setGuideMode(false);
    }
  }, [searchParams, autoPlayMode]);

  useEffect(() => {
    const handleClick = () => {
      if (guideMode) setGuideMode(false);
      document.removeEventListener('pointerdown', handleClick);
      document.removeEventListener('mousedown', handleClick);
      isFirstShowGuideline = false;
    };

    document.addEventListener('pointerdown', handleClick);
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('pointerdown', handleClick);
      document.removeEventListener('mousedown', handleClick);
    };
  }, [guideMode]);

  const [autoOverride, setAutoOverride] = useState(false);

  // Control Ref
  const controlRef = useRef(null);

  // Redux Store
  const shouldLimitPan = useSelector((state) => state.tour.shouldLimitPan);
  const { centerLookAt } = useSelector((state) => state);

  useEffect(() => {
    if (centerLookAt) {
      const { x, y, z } = centerLookAt;
      camera.position.set(x, y, z);
      camera.updateProjectionMatrix();
    }
  }, [camera, centerLookAt]);

  window.toggleOverride = (val) => {
    if (typeof val === 'undefined') {
      setAutoOverride(!autoOverride);
    } else {
      setAutoOverride(val);
    }
  };
  const autoRotate = useMemo(() => {
    if (autoOverride) {
      return false;
    }
    return !!props.autoRotate;
  }, [props.autoRotate, autoOverride]);

  const autoRotateSpeed = useMemo(() => {
    if (!autoRotate) return 0;
    return typeof props.autoRotateSpeed !== 'number'
      ? -1
      : props.autoRotateSpeed;
  }, [autoRotate, props.autoRotateSpeed]);

  const rotateSpeed = useMemo(() => ROTATE_SPEED, []);
  const zoomSpeed = useMemo(() => ZOOM_SPEED, []);
  const enableDamping = useMemo(
    () => !!props.enableDamping,
    [props.enableDamping]
  );

  const [minPolar, maxPolar] = useMemo(() => {
    return shouldLimitPan ? [1.44, 1.71] : [0, Math.PI];
  }, [shouldLimitPan]);

  const enable = useMemo(() => {
    return !!props.enable;
  }, [props.enable]);

  useFrame(() => {
    if (!autoOverride) {
      controlRef?.current && controlRef.current.update();
    }
  });
  const shouldRotate = useMemo(() => {
    if (!autoRotate && !autoPlayMode) {
      return false;
    }
    return true;
  }, [autoPlayMode, autoRotate]);

  const [ena1, auto1, autoSpeed1] = useMemo(() => {
    if (guideMode) {
      return [true, true, 0.2];
    }
    return [enable, shouldRotate, autoRotateSpeed];
  }, [guideMode, enable, shouldRotate, autoRotateSpeed]);

  return (
    <orbitControls
      ref={controlRef}
      args={[camera, gl.domElement]}
      enabled={ena1}
      autoRotate={auto1}
      autoRotateSpeed={autoSpeed1}
      rotateSpeed={rotateSpeed}
      enableZoom={true}
      zoomSpeed={zoomSpeed}
      minZoom={configs.zoom.min}
      maxZoom={configs.zoom.max}
      enableDamping={enableDamping}
      enableKeys={true}
      keyPanSpeed={15}
      minPolarAngle={minPolar}
      maxPolarAngle={maxPolar}
    />
  );
};

export default Controls;
