import { vec4, mat4 } from 'gl-matrix';
import { MercatorCoordinate, LngLat } from 'mapbox-gl';

function clamp(n, min, max) {
  return Math.min(max, Math.max(min, n));
}

function mercatorXfromLng(lng) {
  return (180 + lng) / 360;
}

function mercatorYfromLat(lat) {
  return (
    (180 -
      (180 / Math.PI) *
        Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360))) /
    360
  );
}

const earthRadius = 6371008.8;
const earthCircumfrence = 2 * Math.PI * earthRadius; // meters

function circumferenceAtLatitude(latitude) {
  return earthCircumfrence * Math.cos((latitude * Math.PI) / 180);
}

function mercatorZfromAltitude(altitude, lat) {
  return altitude / circumferenceAtLatitude(lat);
}

const defaultOptions = {
  center: [0, 0],
  zoom: 0,
  bearing: 0,
  pitch: 0,
  width: 0,
  height: 0,
};

class MapBoxUtils {
  tileSize = 512;
  scale = 1;
  worldSize = 1;
  width = 0;
  height = 0;
  center = new LngLat(0, 0);
  _fov = 0.6435011087932844;
  maxValidLatitude = 85.051129;
  zoom = 1;

  constructor(options) {
    options = { ...defaultOptions, ...options };
    this.width = options.width;
    this.height = options.height;
    this._pitch = options.pitch;
    this.zoom = options.zoom;
    this.scale = this.zoomScale(options.zoom);
    this.worldSize = this.tileSize * this.scale;
    this.center = LngLat.convert(options.center);
    this.constrain();
    this.calcMatrixes();
  }

  tProject(lnglat) {
    const lat = clamp(
      lnglat.lat,
      -this.maxValidLatitude,
      this.maxValidLatitude
    );
    return {
      x: mercatorXfromLng(lnglat.lng) * this.worldSize,
      y: mercatorYfromLat(lat) * this.worldSize,
    };
  }

  get point() {
    return this.tProject(this.center);
  }
  get size() {
    return {
      x: this.width,
      y: this.height,
    };
  }

  calcMatrixes() {
    if (!this.height) return;

    const cameraToCenterDistance =
      (0.5 / Math.tan(this._fov / 2)) * this.height;
    const halfFov = this._fov / 2;
    const groundAngle = Math.PI / 2 + this._pitch;
    const topHalfSurfaceDistance =
      (Math.sin(halfFov) * cameraToCenterDistance) /
      Math.sin(clamp(Math.PI - groundAngle - halfFov, 0.01, Math.PI - 0.01));
    const point = this.point;
    const x = point.x,
      y = point.y;

    const furthestDistance =
      Math.cos(Math.PI / 2 - this._pitch) * topHalfSurfaceDistance +
      cameraToCenterDistance;
    const farZ = furthestDistance * 1.01;
    const nearZ = this.height / 50;

    let m = new Float64Array(16);
    mat4.perspective(m, this._fov, this.width / this.height, nearZ, farZ);

    mat4.scale(m, m, [1, -1, 1]);
    mat4.translate(m, m, [0, 0, -cameraToCenterDistance]);
    mat4.rotateX(m, m, this._pitch);
    mat4.rotateZ(m, m, 0);
    mat4.translate(m, m, [-x, -y, 0]);
    this.mercatorMatrix = mat4.scale([], m, [
      this.worldSize,
      this.worldSize,
      this.worldSize,
    ]);

    // scale vertically to meters per pixel (inverse of ground resolution):
    mat4.scale(m, m, [
      1,
      1,
      mercatorZfromAltitude(1, this.center.lat) * this.worldSize,
      1,
    ]);

    this.projMatrix = m;

    m = mat4.create();
    mat4.scale(m, m, [this.width / 2, -this.height / 2, 1]);
    mat4.translate(m, m, [1, -1, 0]);
    this.labelPlaneMatrix = m;

    this.pixelMatrix = mat4.multiply(
      new Float64Array(16),
      this.labelPlaneMatrix,
      this.projMatrix
    );
  }

  constrain() {
    if (!this.center || !this.width || !this.height || this._constraining)
      return;

    this._constraining = true;

    let minY = -90;
    let maxY = 90;
    let minX = -180;
    let maxX = 180;
    let sy, sx, x2, y2;
    const unmodified = this._unmodified;

    const point = this.point;

    // how much the map should scale to fit the screen into given latitude/longitude ranges
    const s = Math.max(sx || 0, sy || 0);

    if (s) {
      this.center = this.unproject({
        x: sx ? (maxX + minX) / 2 : point.x,
        y: sy ? (maxY + minY) / 2 : point.y,
      });
      this.zoom += this.scaleZoom(s);
      this._unmodified = unmodified;
      this._constraining = false;
      return;
    }

    // pan the map if the screen goes off the range
    if (x2 !== undefined || y2 !== undefined) {
      this.center = this.unproject({
        x: x2 !== undefined ? x2 : point.x,
        y: y2 !== undefined ? y2 : point.y,
      });
    }

    this._unmodified = unmodified;
    this._constraining = false;
  }

  zoomScale(zoom) {
    return Math.pow(2, zoom);
  }
  scaleZoom(scale) {
    return Math.log(scale) / Math.LN2;
  }

  locationCoordinate(lnglat) {
    return MercatorCoordinate.fromLngLat(LngLat.convert(lnglat));
  }

  project(lnglat) {
    return this.coordinatePoint(this.locationCoordinate(lnglat));
  }

  unproject(point) {
    return new MercatorCoordinate(
      point.x / this.worldSize,
      point.y / this.worldSize
    ).toLngLat();
  }

  coordinatePoint(coord) {
    const p = [coord.x * this.worldSize, coord.y * this.worldSize, 0, 1];
    vec4.transformMat4(p, p, this.pixelMatrix);
    return {
      x: p[0] / p[3],
      y: p[1] / p[3],
    };
  }
}

export default MapBoxUtils;
