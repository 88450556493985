import { put } from 'redux-saga/effects';
import * as APP_ACTION from './actionTypes';

function kickStart() {
  return new Promise((resolve) => {
    setTimeout(() => resolve('DONE'), 0);
  });
}

function* initiate() {
  try {
    const result = yield kickStart();
    window.logMessage('initiation: ' + result);
    yield put({
      type: APP_ACTION.INITIATED,
    });
  } catch (error) {}
}

const middlewares = {
  initiate,
};

export default middlewares;
