import {
  calRotationYDefaultAndCenterLookAt,
  updateVector3OrPointByRotationXZ,
} from 'containers/world/utils';
import React, { useEffect, useMemo } from 'react';

export default function RotationHandler({
  cameraParams,
  currentPano,
  sceneRotation,
  setRotationY,
}) {
  const { defaultOrientation } = currentPano;

  const defaultOrientationAdj = useMemo(() => {
    if (currentPano && sceneRotation?.prevRot) {
      return updateVector3OrPointByRotationXZ(
        currentPano?.defaultOrientation,
        sceneRotation?.prevRot?.rad || 0,
        false
      );
    }
    return defaultOrientation;
  }, [currentPano, defaultOrientation, sceneRotation]);

  const { lookAtPoint } = useMemo(() => {
    if (cameraParams?.lookAtPoint && cameraParams?.fov) {
      return { lookAtPoint: cameraParams.lookAtPoint, fov: cameraParams.fov };
    }
    return { lookAtPoint: defaultOrientationAdj, fov: 60 };
  }, [cameraParams, defaultOrientationAdj]);

  useEffect(() => {
    const rotationY = calRotationYDefaultAndCenterLookAt(
      defaultOrientation,
      lookAtPoint
    );
    setRotationY(rotationY);
  }, [defaultOrientation, lookAtPoint, setRotationY]);

  return <></>;
}
