import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const PopupPasscode = (props) => {
  const { t } = useTranslation();
  const { onClose, tourPasscode } = props;
  const [passcode, setPasscode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passcode === tourPasscode) {
      setErrorMessage('');
      handleClose();
    } else {
      setErrorMessage(t('incorrect_passcode'));
    }
  };

  const handleClose = () => {
    setPasscode('');
    setErrorMessage('');
    onClose();
  };

  return (
    <div className="passcode-container">
      <div className="popup-passcode">
        <div className="popup-passcode-content">
          <h1 className="popup-passcode-title">{t('enter_passcode')}</h1>
          <form onSubmit={handleSubmit} className="form-passcode">
            <div className="input-container">
              <input
                type="password"
                value={passcode}
                onChange={handlePasscodeChange}
                placeholder={t('please_enter_your_passcode_here')}
                className="input-passcode"
                ref={(element) => element?.focus?.()}
              />
              <p className={`error-message`}>{errorMessage}</p>
            </div>
            <button
              type="submit"
              className={`access-popup-pc ${passcode === '' ? 'disabled' : ''}`}
              disabled={passcode === ''}
            >
              {t('access')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupPasscode;
