import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useThree } from '@react-three/fiber';
import { Texture, FrontSide } from 'three';
import { gsap } from 'gsap';
import { cleanLoadTexture } from '../../utils/textureHelper';
import { get2DScreenPosition } from '../../utils/positionHelper';
import configs from '../../configs';
import { Point } from './marker';
import * as bubbleUtils from './bubblePositioning';

const { SCALE } = bubbleUtils;

const BOX_WIDTH = 10 * SCALE;
export const MAP_SIZE = 1024; // max: 1280
export const MAP_STYLE = {
  STREET: 'mapbox/streets-v11',
  SATELLITE: 'mapbox/satellite-v9',
  SATELLITE_STREET: 'mapbox/satellite-streets-v11',
  // CUSTOM: 'benrobbins/ckg135uru2tue19qmssh0ob8t',
};

const getMapBoxImgUrl = (lat, lng, zoom, bearing, mapConfig) => {
  let styles = MAP_STYLE.SATELLITE_STREET;
  let mapBoxToken = configs.mapBoxToken;
  if (mapConfig.mapBoxAccessToken) {
    mapBoxToken = mapConfig.mapBoxAccessToken;
  }
  if (mapConfig.mapBoxStyle) {
    styles = mapConfig.mapBoxStyle;
  }
  return `https://api.mapbox.com/styles/v1/${styles}/static/${lng},${lat},${zoom},${
    bearing || '0'
  }/${MAP_SIZE}x${MAP_SIZE}@2x?access_token=${mapBoxToken}`;
};

const sidePxUrl = configs.baseUrl + '/assets/images/earth_crust_px.jpg';
const sideNxUrl = configs.baseUrl + '/assets/images/earth_crust_nx.jpg';
const sidePyUrl = configs.baseUrl + '/assets/images/earth_crust_py.jpg';
const sideNyUrl = configs.baseUrl + '/assets/images/earth_crust_ny.jpg';

const rotateByDeg = (deg) => -(deg / 360) * 2 * Math.PI;

const currRotate = {
  z: 0,
};

// const getBearing = (deg) => {
//   const bearing = Math.round(deg / 90) * 90 - 45;
//   window.logMessage('bearing', bearing);
//   return bearing;
// };

const SNAP_SPEED = 0.5;

// let currentBearing = 0;
const ROTATE_MAP_IMAGE = false;

function Box(props) {
  const obj = useRef();
  const { mapConfig, rotateDeg, updateCenter, aerialScenes, isDragging } =
    props;
  const [aerials, setAerials] = useState([]);
  const [iconVisible, setIconVisible] = useState(false);
  const { gl, camera, size } = useThree();

  useEffect(() => {
    camera.position.set(0, -10 * SCALE, 9 * SCALE);
    camera.lookAt(0, 0, 0);
  }, [camera]);

  const maxAnisotropy = useMemo(() => gl.capabilities.getMaxAnisotropy(), [gl]);

  const [texture, setTexture] = useState(null);
  const [t0, setT0] = useState(null);
  const [t90, setT90] = useState(null);
  const [t180, setT180] = useState(null);
  const [t270, setT270] = useState(null);

  useEffect(() => {
    if (obj.current) {
      const newZ = rotateByDeg(rotateDeg);
      if (isDragging) {
        obj.current.rotation.z = newZ;
      } else {
        currRotate.z = obj.current.rotation.z;
        gsap.to(currRotate, {
          z: newZ,
          duration: SNAP_SPEED,
          onUpdate: () => {
            if (obj.current) {
              obj.current.rotation.z = currRotate.z;
            }
          },
        });
        // if (ROTATE_MAP_IMAGE) {
        //   const bearing = getBearing(rotateDeg)
        //   if (bearing !== currentBearing) {
        //     if (bearing === 90) {
        //       setTexture(t90)
        //     } else if (bearing === 180) {
        //       setTexture(t180)
        //     } else if (bearing === 270) {
        //       setTexture(t270)
        //     } else {
        //       setTexture(t0)
        //     }
        //     currentBearing = bearing
        //   }
        // }
      }
    }
  }, [rotateDeg, isDragging, t0, t90, t180, t270]);

  useEffect(() => {
    const {
      center: [lng, lat],
    } = mapConfig;
    const zoom = Math.floor(mapConfig.zoom * 100) / 100;
    window.logMessage('aerialScenes', aerialScenes);
    cleanLoadTexture(
      getMapBoxImgUrl(lat, lng, zoom, null, mapConfig),
      maxAnisotropy
    ).then((img) => {
      setTexture(img);
      setT0(img);
      setTimeout(() => setIconVisible(true), 500);
    });
    if (ROTATE_MAP_IMAGE) {
      cleanLoadTexture(
        getMapBoxImgUrl(lat, lng, zoom, 90, mapConfig),
        maxAnisotropy
      ).then(setT90);
      cleanLoadTexture(
        getMapBoxImgUrl(lat, lng, zoom, 180, mapConfig),
        maxAnisotropy
      ).then(setT180);
      cleanLoadTexture(
        getMapBoxImgUrl(lat, lng, zoom, 270, mapConfig),
        maxAnisotropy
      ).then(setT270);
    }
    const positioned = bubbleUtils.getBubbleDefaultPosition(
      aerialScenes.map((scene) => {
        scene.coords = {
          x: ((scene.mapCoords.x - MAP_SIZE / 2) / MAP_SIZE) * BOX_WIDTH,
          y: ((MAP_SIZE / 2 - scene.mapCoords.y) / MAP_SIZE) * BOX_WIDTH,
        };
        if (
          Math.abs(scene.coords.x) < BOX_WIDTH / 2 &&
          Math.abs(scene.coords.y) < BOX_WIDTH / 2
        ) {
          scene.coords.visible = true;
        }
        return scene;
      })
    );
    setAerials(
      bubbleUtils.calcBubblePosition(positioned.filter((p) => p.coords.visible))
    );
  }, [mapConfig, maxAnisotropy, aerialScenes]);

  const { width, height } = size;
  useEffect(() => {
    const center = get2DScreenPosition(
      { x: 0, y: 0, z: 0 },
      camera,
      width,
      height
    );
    updateCenter(center);
  }, [width, height, camera, updateCenter, isDragging]);

  const sideTexture = new Texture();
  const [pxTexture, setPxTexture] = useState(sideTexture);
  const [nxTexture, setNxTexture] = useState(sideTexture);
  const [pyTexture, setPyTexture] = useState(sideTexture);
  const [nyTexture, setNyTexture] = useState(sideTexture);

  useEffect(() => {
    window.logMessage('load side textures for map');
    cleanLoadTexture(sidePxUrl, maxAnisotropy).then((txt) => setPxTexture(txt));
    cleanLoadTexture(sideNxUrl, maxAnisotropy).then((txt) => setNxTexture(txt));
    cleanLoadTexture(sidePyUrl, maxAnisotropy).then((txt) => setPyTexture(txt));
    cleanLoadTexture(sideNyUrl, maxAnisotropy).then((txt) => setNyTexture(txt));
  }, [maxAnisotropy]);

  return (
    <group ref={obj} position={[0, 0, 0]}>
      <mesh
        {...props}
        position={[0, 0, -0.5 * SCALE]}
        visible={texture !== null}
      >
        <boxGeometry
          attach="geometry"
          args={[BOX_WIDTH, BOX_WIDTH, 1 * SCALE]}
        />
        <meshStandardMaterial
          attach="material-0"
          color="white"
          map={pxTexture}
          side={FrontSide}
        />
        {/* px */}
        <meshStandardMaterial
          attach="material-1"
          color="white"
          map={nxTexture}
          side={FrontSide}
        />
        {/* nx */}
        <meshStandardMaterial
          attach="material-2"
          color="white"
          map={pyTexture}
          side={FrontSide}
        />
        {/* py */}
        <meshStandardMaterial
          attach="material-3"
          color="white"
          map={nyTexture}
          side={FrontSide}
        />
        {/* ny */}
        {/* map face */}
        <meshStandardMaterial
          attach="material-4"
          color="white"
          map={texture}
          side={FrontSide}
        />
        {/* pz */}
        {/* end map face */}
        <meshStandardMaterial attach="material-5" color="white" />
        {/* nz */}
      </mesh>
      {iconVisible &&
        aerials.map((aerial, index) => (
          <Point
            key={index}
            aerial={{ ...aerial }}
            position={[aerial.coords.x, aerial.coords.y, 0.01]}
            scale={SCALE}
            tour={props.tour}
            toggleRotate={() => true}
            onClickBubble={props.onClickBubble}
            visited={false}
            scope={props.scope}
            history={props.history}
            hotspotHistory={props.hotspotHistory}
            // visited={props.hotspotHistory.indexOf(aerial._id) !== -1}
          />
        ))}
    </group>
  );
}

export default Box;
