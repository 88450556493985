export const ERROR_MESSAGES = {
  NO_JSON:
    'The Tour you are looking for is not available for browsing, please get back later!',
  NO_TOUR:
    'The Tour you are looking for is not available at the moment, please get back later!',
  TOUR_INACTIVE: 'Tour is inactive at the moment, please get back later!',
  INVALID_IP: 'The Tour you are looking for is not available for public!',
  NO_GROUP: 'Group is missing',
  NO_SCENE: 'Scene is missing',
  GENERAL:
    'The Tour you are looking for is not available at the moment, please get back later!',
};
