import { useThree } from '@react-three/fiber';
import { useMemo } from 'react';

export default () => {
  const { gl } = useThree();
  window.maxAnisotropy = useMemo(
    () => gl.capabilities.getMaxAnisotropy(),
    [gl]
  );
  return null;
};
