import React, { useRef, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { connect } from 'react-redux';
import { isMenuLeft } from '../../utils/menuHelper';
import { formatPopupStyle } from '../../utils/cssHelper';
import IconClose from './iconClose';
import { useNavigatedRoutes } from 'common/hooks';
import './style.scss';

function Popup(props) {
  const popupBody = useRef(null);
  const [handleUpdateRoute] = useNavigatedRoutes();
  useEffect(() => {
    const popupBodyEl = popupBody?.current;
    if (popupBodyEl) disableBodyScroll(popupBodyEl);
    return () => popupBodyEl && enableBodyScroll(popupBodyEl);
  }, []);
  const { dimentions, hideCloseBtn, containerStyle, color1, color2, color3 } =
    props;
  // lightboxHeaderColor
  return (
    <div
      className="popup-container"
      style={containerStyle || formatPopupStyle(color1, color2, color3)}
    >
      {!hideCloseBtn && (
        <div className="popup-header">
          <span className="Close-button" onClick={() => handleUpdateRoute()}>
            <IconClose />
          </span>
        </div>
      )}
      <div
        ref={popupBody}
        className={`popup-body ${
          isMenuLeft(dimentions) ? 'Menu-left' : 'Menu-bottom'
        }`}
      >
        <div className="popup-body-content">{props.children}</div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ dimentions, tour }) => ({
  dimentions,
  color1: tour?.lightboxBackgroundColor1 || '',
  color2: tour?.lightboxBackgroundColor2 || '',
  color3: tour?.lightboxBackgroundColor3 || '',
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
