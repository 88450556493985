import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { canFullscreen, toggleFullScreen } from 'utils';
import configs from 'configs';

import { useNavigatedRoutes } from 'common/hooks';
import {
  AutoRotateIcon,
  ContactFormIcon,
  FullScreenIcon,
  AudioIcon,
  MapIcon,
  FloorMapIcon,
  PinIcon,
  HelpIcon,
  MapFloorPlanIcon,
  ExpandIcon,
  ShareIcon,
} from 'components/icons';
import PanoMenu from 'components/panoMenu';
import { limitLetters } from 'utils/limitLetters';
import { AutoPlayIcon } from 'components/icons/AutoPlayIcon';
import { setAutoPlay } from 'store/actions';
import GalleryMenu from 'components/GalleryMenu';
import { LAYOUT_UI } from 'consts/ui.const';
import SchoolLayoutMenu from 'components/SchoolLayoutMenu';

import SchoolLayoutIcons from './SchoolLayoutIcons';
import AACorporationLayout from 'components/AACorporationLayout';
import AquaLayoutIcons from './AquaLayoutIcons';
import AquaExtraIcons from './AquaExtraIcons';
import KhaiHoanPrimeLayout from 'components/KhaiHoanPrimeLayout';
import PrimeLayoutIcons from './PrimeLayoutIcons';

const MenuForDesktop = (props) => {
  const {
    menuList,
    isNavOpen,
    menuPosition,
    currentPano,
    subTitle,
    menuOpenScene,
    toggleRotate,
    toggleMenu,
    shouldRotate,
    featuredMedia,
    color,
    isShowOnMap,
    isShowAudioIcon,
    isShowMapFloorPlan,
    floorMaps,
    tour,
    showLanding,
    groups,
    onSelect,
    scenes,
    openMenu,
    setOpenMenu,
    isAquaStyle,
    isPrimeStyle,
    cameraParams,
    sceneRotation,
  } = props;
  const [handleUpdateRoute] = useNavigatedRoutes();
  const [fullScreen, setFullScreen] = useState(false);

  const autoPlayMode = useSelector((state) => state.autoPlayMode);
  const [isAutoPlay, setIsAutoPlay] = useState(autoPlayMode);
  const [isActiveMap, setIsActiveMap] = useState(window.innerWidth > 767);

  const [openDropDown, setOpenDropDown] = useState(false);
  const [mapShowed, setMapShowed] = useState();

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('khp-menu-open');
    } else {
      document.body.classList.remove('khp-menu-open');
    }
  }, [openMenu]);

  useEffect(() => {
    setOpenDropDown(false);
  }, [currentPano]);

  const isMenuGallery = useSelector(
    ({ searchParams }) => searchParams['menu-ui'] === 'gallery'
  );

  const shouldShowMapIcon = useMemo(
    () =>
      tour.mapCoordinates.length > 0 &&
      !(tour.mapCoordinates[0] === 0 && tour.mapCoordinates[1] === 0),
    [tour]
  );

  const isSchoolLayout = useMemo(
    () => tour.menuStyle === LAYOUT_UI.SCHOOL,
    [tour]
  );

  const toggleHelpButton = () => {
    showLanding();
  };

  const handleFullScreen = () => {
    toggleFullScreen();
    setFullScreen(!fullScreen);
  };

  const dispatch = useDispatch();

  const switchMode = useCallback(() => {
    setIsAutoPlay(!isAutoPlay);
    dispatch(setAutoPlay(!isAutoPlay));
  }, [dispatch, isAutoPlay]);

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  

  useEffect(() => {
    if (floorMaps.length > 0 && currentPano) {
      
      const getCurrentMap = (floorMaps, pano) => {
        return floorMaps.find((item) =>
          item.objects.length
            ? item.objects.some((obj) => obj.id === pano._id)
            : item
        );
      };

      const intialMap = getCurrentMap(floorMaps, currentPano);

      setMapShowed(intialMap);
    }
  }, [currentPano, floorMaps]);

  const handleSelectMap = (item) => {
    setMapShowed(item);
  };

  return (
    <div
      className={`menuV2-desktop--container ${
        isAquaStyle || isPrimeStyle ? 'aacorp' : ''
      }    ${isPrimeStyle ? 'prime' : ''}`}
    >
      {isMenuGallery && (
        <GalleryMenu
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
        />
      )}

      {isSchoolLayout && (
        <SchoolLayoutMenu
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
        />
      )}

      {isAquaStyle && (
        <AACorporationLayout
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
          tourLogo={props.tour.logoURL}
        />
      )}

      {isPrimeStyle && (
        <KhaiHoanPrimeLayout
          menuList={menuList}
          scenes={scenes}
          desktop={true}
          groups={groups}
          onSelect={onSelect}
          activeColor={color}
          currentPano={currentPano}
          openMenu={openMenu}
          handleOpenMenu={handleOpenMenu}
          isSchoolLayout={isSchoolLayout}
          tourLogo={props.tour.logoURL}
          isPrimeStyle={isPrimeStyle}
          cameraParams={cameraParams}
          sceneRotation={sceneRotation}
        />
      )}

      <div
        className={`menuV2-desktop--container_left ${
          isMenuGallery
            ? `container-width ${!openMenu && 'container-width-closed'}`
            : ''
        }`}
      >
        <div>
          {!isMenuGallery &&
            !isSchoolLayout &&
            !isAquaStyle &&
            !isPrimeStyle && (
              <div
                className="header"
                onClick={() => toggleMenu(tour.isPublic ? false : !isNavOpen)}
                style={{ cursor: ` ${tour.isPublic ? 'default' : 'pointer'}` }}
              >
                <div className="header_icon">
                  <PinIcon color={color} />
                </div>
                <div className="header_titles">
                  <div className="header_titles_title" style={{ color }}>
                    {currentPano ? limitLetters(currentPano.title, 100) : null}
                  </div>
                  <p className="header_titles_label">{subTitle || null}</p>
                </div>
              </div>
            )}
          <PanoMenu
            isOpen={isNavOpen}
            menu={menuList}
            location={menuPosition}
            onClose={() => toggleMenu(false)}
            onSelect={menuOpenScene}
            color={color}
          />
        </div>
      </div>
      <div className="menuV2-desktop--container_right">
        <div
          className={`icons_left ${
            isMenuGallery && !openMenu ? 'margin-left__0' : ''
          }`}
        >
          {shouldShowMapIcon && !isAquaStyle && !isPrimeStyle && (
            <div
              className="icon"
              style={{
                display: isShowOnMap ? 'flex' : 'none',
              }}
              onClick={() => handleUpdateRoute(configs.mapRoute)}
            >
              <MapIcon />
            </div>
          )}
        </div>
        {isSchoolLayout && (
          <SchoolLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
            isAquaStyle={isAquaStyle}
          />
        )}

        {isAquaStyle && (
          <AquaLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
            isAquaStyle={isAquaStyle}
            shouldShowMapIcon={shouldShowMapIcon}
            isShowOnMap={isShowOnMap}
          />
        )}

        {isPrimeStyle && (
          <PrimeLayoutIcons
            currentPano={currentPano}
            isAutoPlay={isAutoPlay}
            color={color}
            toggleHelpButton={toggleHelpButton}
            featuredMedia={featuredMedia}
            isShowAudioIcon={isShowAudioIcon}
            toggleRotate={toggleRotate}
            shouldRotate={shouldRotate}
            canFullscreen={canFullscreen}
            menuPosition={menuPosition}
            handleFullScreen={handleFullScreen}
            fullScreen={fullScreen}
            isShowMapFloorPlan={isShowMapFloorPlan}
            setIsActiveMap={setIsActiveMap}
            isActiveMap={isActiveMap}
            mapShowed={mapShowed}
            setOpenDropDown={setOpenDropDown}
            openDropDown={openDropDown}
            handleSelectMap={handleSelectMap}
            scenes={scenes}
            onSelect={onSelect}
            switchMode={switchMode}
            handleUpdateRoute={handleUpdateRoute}
            isSchoolLayout={isSchoolLayout}
            shouldShowMapIcon={shouldShowMapIcon}
            isShowOnMap={isShowOnMap}
            isPrimeStyle={isPrimeStyle}
          />
        )}

        {!isSchoolLayout && !isAquaStyle && !isPrimeStyle && (
          <div className="icons_right">
            {shouldShowMapIcon && isAquaStyle && (
              <div
                className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
                style={{
                  display: isShowOnMap ? 'flex' : 'none',
                }}
                onClick={() => handleUpdateRoute(configs.mapRoute)}
              >
                <MapIcon />
              </div>
            )}

            {isAquaStyle && <AquaExtraIcons />}

            <div
              className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
              onClick={() => switchMode()}
            >
              <AutoPlayIcon shouldRotate={isAutoPlay} activeColor={color} />
            </div>

            <div
              className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
              onClick={toggleHelpButton}
            >
              <HelpIcon />
            </div>
            {featuredMedia.map && (
              <div
                className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
                onClick={() => handleUpdateRoute(featuredMedia.map.id)}
              >
                <FloorMapIcon />
              </div>
            )}
            {featuredMedia.contact && (
              <div
                className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
                onClick={() => handleUpdateRoute(featuredMedia.contact.id)}
              >
                <ContactFormIcon />
              </div>
            )}
            {isShowAudioIcon && (
              <AudioIcon
                activeColor={color}
                className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
              />
            )}
            <div
              className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
              onClick={() => {
                toggleRotate();
              }}
            >
              <AutoRotateIcon shouldRotate={shouldRotate} activeColor={color} />
            </div>
            {canFullscreen() && menuPosition !== 'bottom' && (
              <div
                className={`icon ${isAquaStyle ? 'icon-aquaStyle' : ''}`}
                onClick={handleFullScreen}
              >
                <FullScreenIcon fullScreen={fullScreen} activeColor={color} />
              </div>
            )}
            {isShowMapFloorPlan && (
              <>
                <div
                  className={`icon position-relative ${
                    isAquaStyle ? 'icon-aquaStyle' : ''
                  }`}
                  onClick={() => setIsActiveMap(!isActiveMap)}
                >
                  <MapFloorPlanIcon active={isActiveMap} />
                </div>
                <div
                  className={`mapfloorplan_container ${
                    isActiveMap ? 'open' : ''
                  }  ${
                    isActiveMap && isAquaStyle
                      ? 'mapfloorplan_container-aacorp open-aacorp'
                      : ''
                  }`}
                >
                  {mapShowed && (
                    <>
                      {floorMaps?.length > 1 && !isAquaStyle && (
                        <div className="map_header">
                          <div
                            className="map_dropdown"
                            onClick={() => setOpenDropDown(!openDropDown)}
                          >
                            <span>
                              {limitLetters(mapShowed.image.name, 15)}
                            </span>
                            <i className="map_arrow down"></i>
                            {openDropDown && (
                              <div
                                className={`map_dropdown_options ${
                                  openDropDown ? 'show' : ''
                                }`}
                              >
                                <ul className="dropdown_items">
                                  {floorMaps?.map((item, index) => (
                                    <li
                                      onClick={() => handleSelectMap(item)}
                                      key={index}
                                    >
                                      {limitLetters(item.image.name, 10)}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div
                            className={`icon ${
                              isAquaStyle ? 'icon-aquaStyle' : ''
                            }`}
                          >
                            <ExpandIcon />
                          </div>
                        </div>
                      )}
                      <div className="map_image">
                        <img
                          src={mapShowed.image.url}
                          alt={mapShowed.image.name}
                        />
                        {mapShowed.objects.map((viewpoint) => {
                          const currentPoint = viewpoint.id === currentPano._id;
                          const [left, top] = viewpoint.coordinates;
                          const leftInPercent = left * 100;
                          const topInPercent = top * 100;
                          const currentScene =
                            scenes.find((s) => s._id === viewpoint.id) || {};

                          return (
                            <div
                              key={viewpoint.id}
                              className={`viewpoint-container ${
                                currentPoint ? 'current-viewpoint' : 'viewpoint'
                              } `}
                              style={{
                                left: `${leftInPercent}%`,
                                top: `${topInPercent}%`,
                              }}
                              onClick={() =>
                                onSelect(currentScene.groupId, currentScene.id)
                              }
                            >
                              <p className="scene-title">
                                {currentScene.title}
                              </p>
                            </div>
                          );
                        })}
                      </div>

                      {isAquaStyle && (
                        <div
                          className="mapfloorplan_close"
                          onClick={() => setIsActiveMap(false)}
                        >
                          <button className="mapfloorplan_close-btn">
                            &times;
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {isAquaStyle && (
                  <div className="tour-icons">
                    <div
                      className="icon-share"
                      onClick={() => handleUpdateRoute(configs.socialRoute)}
                    >
                      <ShareIcon color={color} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuForDesktop;
