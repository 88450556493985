import React from 'react';

import ShapeSparkIcon from 'assets/icons/shapespark.png';

function iconShapeSpark(props) {
  return (
    <div className="hotspot_icon">
      <img
        alt="ShapeSpark-icon"
        src={ShapeSparkIcon}
        style={{ width: '60%' }}
      ></img>
    </div>
  );
}

export default iconShapeSpark;
