import React, { useEffect, useState } from 'react';

import './AquaModal.scss';

export default function AquaModal({ isOpen, iframeObject, handleClose }) {
  const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setShouldRenderIframe(true);
      }, 3000);
    } else {
      setShouldRenderIframe(false);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    if (shouldRenderIframe) {
      setIsLoading(false);
    }
  }, [shouldRenderIframe]);

  return (
    <div className="aqua-modal" style={{ display: isOpen ? 'block' : 'none' }}>
      <div className="aqua-modal-container">
        {isLoading && <div className="spinner"></div>}
        {shouldRenderIframe && (
          <div className="aqua-modal-iframe__wrapper">
            <iframe
              className={`${
                iframeObject.type === 'youtube' ? 'youtube' : 'map'
              }`}
              src={iframeObject.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <div
              className="aqua-modal-close"
              onClick={() => {
                handleClose();
                setIsLoading(true);
              }}
            >
              <button className="aqua-modal-close__btn">&times;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
