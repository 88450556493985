import React from 'react';

export const ContactFormIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <g fill="#fff" width="30" height="30" transform="translate(22423 1491)">
        <g transform="translate(36.749 3.935)">
          <path
            fill="#fff"
            d="M762.1,182.633H744a3.749,3.749,0,0,0-3.738,3.741v9.05a3.724,3.724,0,0,0,3.644,3.738l1.316.037v2.416l3.571-2.45H762.1a3.749,3.749,0,0,0,3.738-3.741v-9.052A3.75,3.75,0,0,0,762.1,182.633Zm-1.633,12.26H746.391a1.353,1.353,0,0,1,0-2.706H760.47a1.353,1.353,0,1,1,0,2.706Zm0-4.7H746.391a1.353,1.353,0,0,1,0-2.706H760.47a1.353,1.353,0,1,1,0,2.706Z"
            transform="translate(-23197.01 -1671.568)"
          />
        </g>
      </g>
    </svg>
  );
};
