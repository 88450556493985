import React, { memo, useEffect, useRef, useMemo, useState } from 'react';
import { Vector2, Vector3, Raycaster, FrontSide } from 'three';
import { useThree } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import { isMobile } from 'react-device-detect';
import configs from 'configs';
import {
  calcFootRotationY,
  calcScaleForProjection,
} from 'containers/world/utils';
import {
  CURSOR_WIDTH,
  TRANSFORM_RATIO,
  INITIAL_POSITION_CURSOR,
  INITIAL_ROTATION_CURSOR,
  DEFAULT_ROTATION_X_ANGLE,
  THRESHOLD_2D_DISTANCE,
} from 'containers/world/constant';
import { get2DScreenPosition } from 'utils/positionHelper';

const footStepImg =
  configs.baseUrl + '/assets/images/navigation/circleFixed.png';

function FootCursor({
  sceneData,
  visibleSteps,
  prevRotation,
  handleClick,
  calculate2DDistance,
}) {
  const pointerDownTime = useRef(0);
  const pointerDownPosition = useRef([]);
  const clickPoint = useRef([]);
  const meshRef = useRef();
  const [opacity, setOpacity] = useState(0);
  const { camera, scene } = useThree();

  const boxObj = useMemo(
    () => (sceneData.id ? scene.getObjectByName(sceneData.id) : null),
    [scene, sceneData]
  );

  const texture = useTexture(footStepImg);

  const findIntersectionPoint = (boxObj, e) => {
    const cursor = new Vector2();
    const raycaster = new Raycaster();

    cursor.x = (e.clientX / window.innerWidth) * 2 - 1;
    cursor.y = -(e.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(cursor, camera);

    const intersects = raycaster.intersectObjects([boxObj]);

    return intersects.length > 0 ? intersects[0].point.toArray() : null;
  };

  const handleCursorMove = (e) => {
    if (boxObj) {
      const [x, y, z] = findIntersectionPoint(boxObj, e);

      const [x_cursor, y_cursor, z_cursor] = [
        x * TRANSFORM_RATIO,
        y * TRANSFORM_RATIO,
        z * TRANSFORM_RATIO,
      ];
      const angleRotate = calcFootRotationY([x_cursor, y_cursor, z_cursor]);
      const { scaleX, scaleY } = calcScaleForProjection(y);

      if (meshRef.current) {
        meshRef.current.position.set(x_cursor, y_cursor, z_cursor);
        meshRef.current.scale.set(scaleX, scaleY);
        meshRef.current.rotation.set(DEFAULT_ROTATION_X_ANGLE, 0, angleRotate);
        const hasCloseStep = visibleSteps.some((step) => {
          const { distance } = calculate2DDistance(
            step,
            prevRotation,
            camera,
            e
          );
          return distance < THRESHOLD_2D_DISTANCE;
        });
        setOpacity(hasCloseStep ? 0.9 : 0);
      }
    }
  };

  const handlePointerDown = (e) => {
    pointerDownTime.current = Date.now();
    pointerDownPosition.current = [e.clientX, e.clientY];
    clickPoint.current = findIntersectionPoint(boxObj, e);
  };

  const handlePointerUp = (e) => {
    const pointerUpTime = Date.now();
    const pointerUpPosition = [e.clientX, e.clientY];
    const timeDiff = pointerUpTime - pointerDownTime.current;
    const distance = Math.sqrt(
      (pointerUpPosition[0] - pointerDownPosition.current[0]) ** 2 +
        (pointerUpPosition[1] - pointerDownPosition.current[1]) ** 2
    );
    const isClick = timeDiff < 300 && distance < 10;

    if (isClick) {
      const clickPoint2D = get2DScreenPosition(
        new Vector3(...clickPoint.current),
        camera,
        window.innerWidth,
        window.innerHeight
      );
      handleClick(clickPoint2D);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleCursorMove, { passive: true });
    window.addEventListener('pointerdown', handlePointerDown);
    window.addEventListener('pointerup', handlePointerUp);

    return () => {
      window.removeEventListener('mousemove', handleCursorMove, {
        passive: true,
      });
      window.removeEventListener('pointerdown', handlePointerDown);
      window.removeEventListener('pointerup', handlePointerUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, boxObj]);

  return (
    <mesh
      ref={meshRef}
      position={INITIAL_POSITION_CURSOR}
      rotation={INITIAL_ROTATION_CURSOR}
    >
      <planeGeometry args={[CURSOR_WIDTH, CURSOR_WIDTH]} />
      <meshPhongMaterial
        color="white"
        map={texture}
        alphaTest={0.5}
        side={FrontSide}
        transparent
        opacity={isMobile ? 0 : opacity}
      />
    </mesh>
  );
}

export default memo(FootCursor);
