import * as APP_ACTION from './actionTypes';

export const initiate = () => ({ type: APP_ACTION.INITIATE });
export const updateDimentions = (payload) => ({
  type: APP_ACTION.UPDATE_DIMENTIONS,
  payload,
});
export const togglePanoMode = (mode) => ({
  type: APP_ACTION.TOGGLE_PANO_MODE,
  payload: mode,
});
export const toggleMenu = (isOpen) => ({
  type: APP_ACTION.TOGGLE_MENU,
  payload: isOpen,
});
export const setMenuPosition = (payload) => ({
  type: APP_ACTION.SET_MENU_POSITION,
  payload,
});
export const setTourData = (payload) => ({
  type: APP_ACTION.SET_TOUR_DATA,
  payload,
});
export const setPanoData = (payload) => ({
  type: APP_ACTION.SET_PANO_DATA,
  payload,
});

export const goToScene = (groupId, id) => ({
  type: APP_ACTION.GO_TO_PANO,
  payload: { id, groupId },
});

export const closeLanding = () => ({ type: APP_ACTION.CLOSE_LANDING });
export const setAerialMapData = (payload) => ({
  type: APP_ACTION.SET_AERIAL_MAP_DATA,
  payload,
});
export const toggleBlur = (payload) => ({
  type: APP_ACTION.TOGGLE_BLUR,
  payload,
});
export const setAudioAvailable = (payload) => ({
  type: APP_ACTION.SET_AUDIO_AVAILABLE,
  payload,
});
export const setAudioAutoPlay = () => ({
  type: APP_ACTION.SET_AUDIO_AUTOPLAY,
});
export const toggleAudio = (isOn) => ({
  type: APP_ACTION.TOGGLE_AUDIO,
  payload: isOn,
});
export const setTourAudioAvailabe = (isOn) => ({
  type: APP_ACTION.SET_TOUR_AUDIO_AVAILABLE,
  payload: isOn,
});
// Hotspots
export const addHotspotHistory = (payload) => ({
  type: APP_ACTION.ADD_HOTSPOT_HISTORY,
  payload,
});

// Control Mode
export const setGyro = (payload) => ({ type: APP_ACTION.SET_GYRO, payload });
export const setControlMode = (payload) => ({
  type: APP_ACTION.SET_CONTROL_MODE,
  payload,
});

export const setCenterLookat = (payload) => ({
  type: APP_ACTION.SET_CENTER_LOOKAT,
  payload,
});

// Landing
export const toggleLanding = (payload) => ({
  type: APP_ACTION.TOGGLE_LANDING,
  payload,
});

export const setTourError = (payload) => ({
  type: APP_ACTION.SET_TOUR_ERROR,
  payload,
});

//View Mode
export const setViewMode = (payload) => ({
  type: APP_ACTION.SET_VIEW_MODE,
  payload,
});

export const setAutoPlay = (payload) => ({
  type: APP_ACTION.SET_AUTO_PLAY,
  payload,
});

export const updatePrevRotation = (payload) => ({
  type: APP_ACTION.UPDATE_PREV_ROTATION,
  payload,
});

export const setAutoRotate = (payload) => ({
  type: APP_ACTION.SET_AUTO_ROTATE,
  payload,
});

export const setSearchParams = (payload) => ({
  type: APP_ACTION.SET_SEARCH_PARAMS,
  payload,
});

export const setOpenPopUp = (payload) => ({
  type: APP_ACTION.SET_OPEN_POPUP,
  payload,
});

export const updateWorldParams = (payload) => ({
  type: APP_ACTION.UPDATE_WORLD_PARAMS,
  payload,
});

export const setPanning = (payload) => ({
  type: APP_ACTION.SET_IS_PANNING,
  payload,
});

export const setDateLight = (payload) => ({
  type: APP_ACTION.SET_DATE_LIGHT,
  payload,
});

export const setApartmentPopup = (payload) => ({
  type: APP_ACTION.SET_APARTMENT_POPUP,
  payload,
});
