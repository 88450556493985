import React, { useMemo, useState } from 'react';
import TopviewSubmenu from './TopviewSubmenu';
import ScaleArrow from 'components/ScaleArrow';

export default function AquaMenuItem({
  scenes,
  scene,
  onSelect,
  currentPano,
  setOpenMobileMenu,
  setIsOpenTopviewSubmenu,
  setIsOpenMikaTopviewSubmenu,
  isOpenMikaTopviewSubmenu,
  isOpenTopviewSubmenu,
  isTopviewSub,
}) {
  const [hover, setHover] = useState(null);

  const [topViewHover, setTopViewHover] = useState(false);

  const hoverImage = useMemo(
    () => scenes.find((item) => item._id === scene._id),
    [scenes, scene]
  );

  const isMobile = useMemo(() => window.innerWidth < 1023, []);

  return (
    <div
      className={`menu-items-aqua__scenes-item primeStyle ${
        currentPano?.id === scene.id ? 'primeCurrentScene' : ''
      } ${
        scene.id === 'topview' || scene.id === 'mika-topview'
          ? 'position-relative'
          : ''
      }`}
      onMouseOver={() => {
        setHover(scene.order);
        (scene.id === 'topview' || scene.id === 'mika-topview') &&
          setTopViewHover(true);
      }}
      onMouseLeave={() => {
        setHover(null);
        setTopViewHover(false);
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (
          isMobile &&
          (scene.id === 'topview' || scene.id === 'mika-topview')
        ) {
          if (scene.id === 'topview') {
            setIsOpenTopviewSubmenu(!isOpenTopviewSubmenu);
            setIsOpenMikaTopviewSubmenu(false);
          }

          if (scene.id === 'mika-topview') {
            setIsOpenMikaTopviewSubmenu(!isOpenMikaTopviewSubmenu);
            setIsOpenTopviewSubmenu(false);
          }
        } else {
          if (scene.id === 'topview' || scene.id === 'mika-topview') {
            scene.id === 'topview' &&
              onSelect(scene.groupId, 'topview-tang-27');

            scene.id === 'mika-topview' &&
              onSelect(scene.groupId, 'mika-topview-tang-27');
          } else {
            onSelect(scene.groupId || 'welcome', scene.id);
          }

          if (isMobile) {
            isOpenTopviewSubmenu && setIsOpenTopviewSubmenu(false);
            isOpenMikaTopviewSubmenu && setIsOpenMikaTopviewSubmenu(false);
          }

          setOpenMobileMenu && setOpenMobileMenu();
        }
      }}
    >
      {!isMobile && (
        <>
          <p
            className={`menu-items-aqua__scenes-item-title primeStyle ${
              hover === scene.order || currentPano.id === scene.id
                ? 'hovering'
                : ''
            } ${
              scene.id === 'topview' || scene.id === 'mika-topview'
                ? 'plus-sign'
                : ''
            }`}
          >
            {scene.title}
          </p>
          {topViewHover && !isMobile && (
            <TopviewSubmenu
              sceneDetail={scene}
              scenes={scenes}
              currentPano={currentPano}
              onSelect={onSelect}
            />
          )}

          {(scene.id === 'topview' || scene.id === 'mika-topview') && (
            <ScaleArrow fontSize={12} color={'black'} rotate={180} />
          )}
        </>
      )}

      {isMobile && (
        <div className="menu-items-aqua__scenes-item-container">
          <p
            className={`menu-items-aqua__scenes-item-title primeStyle ${
              hover === scene.order || currentPano?.id === scene.id
                ? 'hovering'
                : ''
            } ${isTopviewSub ? 'sub-translateX' : ''}`}
          >
            {scene.id === 'topview' &&
              (isOpenTopviewSubmenu ? (
                <i className="fa fa-minus"></i>
              ) : (
                <i className="fa fa-plus"></i>
              ))}

            {scene.id === 'mika-topview' &&
              (isOpenMikaTopviewSubmenu ? (
                <i className="fa fa-minus"></i>
              ) : (
                <i className="fa fa-plus"></i>
              ))}

            {isTopviewSub && <span className="horizontal-line"></span>}
            {scene.title}
          </p>

          {scene.id === 'topview' && (
            <ScaleArrow
              fontSize={12}
              color={'white'}
              rotate={isOpenTopviewSubmenu ? -90 : 180}
            />
          )}

          {scene.id === 'mika-topview' && (
            <ScaleArrow
              fontSize={12}
              color={'white'}
              rotate={isOpenMikaTopviewSubmenu ? -90 : 180}
            />
          )}
        </div>
      )}

      {!isMobile && scene.id !== 'topview' && (
        <div
          className="menu-items-aqua__scenes-item-image"
          style={{ opacity: hover === scene.order ? 1 : 0 }}
        >
          <img src={hoverImage?.previewImgUrl} alt={hoverImage?.title} />
          <div className="menu-items-aqua__scenes-item-imgTitle">
            <p> {scene.title}</p>
          </div>
        </div>
      )}
    </div>
  );
}
