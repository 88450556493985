import React from 'react';

import './style.scss';
import { ExpandIcon } from 'components/icons';
import { limitLetters } from 'utils/limitLetters';
import HoveringPlate from 'components/HoveringPlate';

export default function MapfloorplanModal({
  isOpen,
  close,
  setIsActiveMap,
  isShowMapFloorPlan,
  isActiveMap,
  mapShowed,
  floorMaps,
  setOpenDropDown,
  openDropDown,
  handleSelectMap,
  currentPano,
  scenes,
  onSelect,
  isPrimeStyle,
}) {
  return (
    <div
      className="prime-modal"
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? 'all' : 'none',
      }}
    >
      <div className="prime-modal-container">
        <div
          className="prime-modal-map"
          style={{ pointerEvents: isOpen ? 'all' : 'none' }}
        >
          <button
            className="prime-modal-cancle"
            onClick={() => {
              close();
              setIsActiveMap(false);
            }}
            style={{ pointerEvents: isOpen ? 'all' : 'none' }}
          >
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
          </button>
          {/* <div style={{ height: 200, width: 340, background: 'white' }}> */}
          {isShowMapFloorPlan && (
            <>
              {/* <HoveringPlate
            textHover={'Mặt bằng tổng thể'}
            isPrimeStyle={isPrimeStyle}
          > */}

              {/* </HoveringPlate> */}

              <div
                className={`mapfloorplan_container  ${
                  isPrimeStyle ? 'mapfloorplan_container-primeStyle' : ''
                }`}
              >
                {mapShowed && (
                  <>
                    {floorMaps?.length > 1 && !isPrimeStyle && (
                      <div className="map_header">
                        <div
                          className="map_dropdown"
                          onClick={() => setOpenDropDown(!openDropDown)}
                        >
                          <span>{limitLetters(mapShowed.image.name, 15)}</span>
                          <i className="map_arrow down"></i>
                          {openDropDown && (
                            <div
                              className={`map_dropdown_options ${
                                openDropDown ? 'show' : ''
                              }`}
                            >
                              <ul className="dropdown_items">
                                {floorMaps?.map((item, index) => (
                                  <li
                                    onClick={() => handleSelectMap(item)}
                                    key={index}
                                  >
                                    {limitLetters(item.image.name, 10)}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div
                          className={`icon ${
                            isPrimeStyle ? 'icon-aquaStyle' : ''
                          }`}
                        >
                          <ExpandIcon />
                        </div>
                      </div>
                    )}
                    <div className="map_image">
                      <img
                        src={mapShowed.image.url}
                        alt={mapShowed.image.name}
                      />
                      {mapShowed.objects.map((viewpoint) => {
                        const currentPoint = viewpoint.id === currentPano._id;
                        const [left, top] = viewpoint.coordinates;
                        const leftInPercent = left * 100;
                        const topInPercent = top * 100;
                        const currentScene =
                          scenes.find((s) => s._id === viewpoint.id) || {};

                        return (
                          <div
                            key={viewpoint.id}
                            className={`viewpoint-container  ${
                              currentPoint
                                ? 'current-viewpoint aquaAnimation primeColor'
                                : `viewpoint ${
                                    isPrimeStyle
                                      ? 'aquaViewPoint primeColor'
                                      : ''
                                  }`
                            } `}
                            style={{
                              left: `${leftInPercent}%`,
                              top: `${topInPercent}%`,
                            }}
                            onClick={() => {
                              onSelect(currentScene.groupId, currentScene.id);
                              close();
                              setIsActiveMap(false);
                            }}
                          >
                            <HoveringPlate textHover={currentScene.title}>
                              {/* <p className="scene-title">{currentScene.title}</p> */}

                              <div className="hover-point"></div>
                            </HoveringPlate>
                          </div>
                        );
                      })}
                    </div>

                    {/* {isPrimeStyle && (
                  <div
                    className="mapfloorplan_close"
                    onClick={() => setIsActiveMap(true)}
                  >
                    <button className="mapfloorplan_close-btn">&times;</button>
                  </div>
                )} */}
                  </>
                )}
              </div>
            </>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
