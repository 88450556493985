import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import './introSlider.scss';
import Tip1 from './tip_1';
import Tip2 from './tip_2';
import Tip3 from './tip_3';
// import Tip4 from './tip_4';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PoweredLogo from 'components/powered/logo';
import { useWindowSize } from '../../common/hooks/window';
import { LANDING_PAGE_URL } from 'consts/url';

const IntroSlider = (props) => {
  const [widthScreen] = useWindowSize();
  const { phone, portrait, onClick } = props;
  const listRef = useRef();
  const [slidePerPage, setSlidePerPage] = useState(1);

  useEffect(() => {
    if (phone) {
      setSlidePerPage(window.innerWidth < window.innerHeight ? 1 : 2);
    } else {
      if (window.innerWidth < 576) {
        setSlidePerPage(1);
      } else if (window.innerWidth < 768) {
        setSlidePerPage(2);
      } else {
        setSlidePerPage(3);
      }
    }
  }, [widthScreen, phone]);

  const settings = {
    dots: Boolean(props.phone),
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: slidePerPage,
    slidesToScroll: slidePerPage,
  };

  return (
    <div
      className={`intro-slide-container ${phone ? 'phone' : 'desktop'} ${
        portrait ? 'portrait' : 'landscape'
      }`}
      onClick={onClick}
    >
      <div ref={listRef} className="intro-slide-item-list">
        <Slider {...settings}>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body" style={{ height: '210px' }}>
              <Tip1 color={portrait ? '#fff' : '#000'} />
              <p className="intro-slide-title">Menu</p>
              <div className="intro-text">
                See a list of locations &amp; control viewing options with the
                menu
              </div>
            </div>
          </div>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body" style={{ height: '210px' }}>
              <Tip2 color={portrait ? 'white' : 'black'} />
              <p className="intro-slide-title">360&deg;</p>
              <div className="intro-text">
                Click and drag to rotate 360&deg; in any direction
              </div>
            </div>
          </div>
          <div className="intro-slide-item">
            <div className="intro-slide-item-body" style={{ height: '210px' }}>
              <Tip3 color={portrait ? 'white' : 'black'} />
              <p className="intro-slide-title">Explore</p>
              <div className="intro-text">
                Fly between locations, open media &amp; see every detail
              </div>
            </div>
          </div>
        </Slider>
        <div
          className="intro-slide-logo"
          onClickCapture={(e) => e.stopPropagation()}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            alt="Open VR"
            href={LANDING_PAGE_URL}
          >
            <PoweredLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default IntroSlider;
