const TYPE = 'image/jpeg';
const SIZES = ['px', 'nx', 'py', 'ny', 'pz', 'nz'];

export const loadImageData = (fileUrl) =>
  new Promise((resolve) => {
    if (fileUrl) {
      var img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(this, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL(TYPE));
      };
      img.onerror = function () {
        resolve(null);
      };
      img.src = fileUrl;
    } else {
      resolve(null);
    }
  });

window.imageQueues = {};
window.imageQueueNo = 0;

var SHOULD_CACHE_HD = true;

window.cancelImageQueues = () => {
  window.imageQueueNo += 1;
  const imgs = Object.keys(window.imageQueues);
  for (let index = 0; index < imgs.length; index++) {
    const img = imgs[index];
    if (window.imageQueues[img] instanceof Image) {
      if (
        !window.imageQueues[img].complete ||
        window.imageQueues[img].naturalHeight <= 0 ||
        !SHOULD_CACHE_HD
      ) {
        window.imageQueues[img].src = '';
        delete window.imageQueues[img];
      }
    }
  }
};

export const loadImageForFrag = (fileUrl) =>
  new Promise((resolve) => {
    if (fileUrl) {
      window.imageQueues[fileUrl] = new Image();
      const img = window.imageQueues[fileUrl];
      img.crossOrigin = 'anonymous';
      img.onload = function () {
        resolve(this);
      };
      img.onerror = function () {
        resolve(null);
      };
      img.src = fileUrl;
    } else {
      resolve(null);
    }
  });

export const getImgForSide = (side, cubeMapImages, SourceImgId) => {
  const i = SIZES.indexOf(side);
  const size1024_4f = cubeMapImages.size1024_4f[i] + '/' + SourceImgId;
  const size1024_16f = cubeMapImages.size1024_16f[i] + '/' + SourceImgId;
  const size1024_4fs = [
    size1024_4f + '-11',
    size1024_4f + '-12',
    size1024_4f + '-21',
    size1024_4f + '-22',
  ];
  const size1024_16fs = [
    size1024_16f + '-11',
    size1024_16f + '-12',
    size1024_16f + '-13',
    size1024_16f + '-14',

    size1024_16f + '-21',
    size1024_16f + '-22',
    size1024_16f + '-23',
    size1024_16f + '-24',

    size1024_16f + '-31',
    size1024_16f + '-32',
    size1024_16f + '-33',
    size1024_16f + '-34',

    size1024_16f + '-41',
    size1024_16f + '-42',
    size1024_16f + '-43',
    size1024_16f + '-44',
  ];

  return {
    size1024: cubeMapImages.size1024[i],
    size2048: cubeMapImages.size2048[i],
    size1024_4f: size1024_4fs,
    size1024_16f: size1024_16fs,
  };
};
