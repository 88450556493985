import React from 'react';

export default function SunIcon() {
  return (
    <svg
      width="21"
      height="22"
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
    >
      <g transform="translate(-294 -51)">
        <path
          d="M5.817 10.4941C5.81798 10.3089 5.82995 10.124 5.85288 9.94022L2.89319 9.94022 2.89319 11.034 5.85069 11.034C5.82905 10.8548 5.81781 10.6746 5.817 10.4941Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M10.5013 5.80978C10.6841 5.81063 10.8667 5.82223 11.0482 5.84456L11.0482 2.87897 9.95444 2.87897 9.95444 5.84456C10.1359 5.82223 10.3185 5.81061 10.5013 5.80978Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M14.1706 7.59063 16.2678 5.49369 15.4945 4.72062 13.3963 6.81866C13.6834 7.04511 13.9433 7.30417 14.1706 7.59063Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M7.60637 6.81866 5.50834 4.72062 4.73506 5.49391 6.832 7.59063C7.05935 7.30417 7.31922 7.04511 7.60637 6.81866Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M13.4043 14.1636 15.4945 16.2531 16.2678 15.4796 14.1768 13.3886C13.9502 13.676 13.691 13.9361 13.4043 14.1636Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M15.1497 9.94022C15.1968 10.3032 15.1976 10.6708 15.1519 11.034L18.1094 11.034 18.1094 9.94022Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M10.5013 15.1786C10.3185 15.1777 10.1359 15.1661 9.95444 15.1438L9.95444 18.0952 11.0482 18.0952 11.0482 15.1438C10.8667 15.166 10.6841 15.1777 10.5013 15.1786Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M6.825 13.3892 4.73419 15.4801 5.50834 16.2531 7.59806 14.1634C7.31132 13.9361 7.05191 13.6763 6.825 13.3892Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
        <path
          d="M14.3106 10.4941C14.3106 12.5979 12.6051 14.3034 10.5013 14.3034 8.39748 14.3034 6.692 12.5979 6.692 10.4941 6.692 8.39027 8.39748 6.68478 10.5013 6.68478 12.6051 6.68478 14.3106 8.39027 14.3106 10.4941Z"
          fill="#FFC000"
          transform="matrix(1 0 0 1.04762 294 51)"
        />
      </g>
    </svg>
  );
}
