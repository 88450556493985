import camelCase from 'camelcase';

export const formatStyle = (style) => {
  let styles = {};
  if (typeof style === 'object') {
    Object.keys(style).forEach((key) => {
      const currKey = camelCase(key);
      styles[currKey] = style[key];
    });
  }
  return styles;
};

export const formatPopupStyle = (clr1, clr2, clr3) => ({
  // backgroundImage: `linear-gradient(to right top, ${
  //   `rgba($color: ${clr1}, $alpha: 0.75)` || 'rgba(0, 0, 0, 0.5)'
  // }, ${`rgba($color: ${clr2}, $alpha: 0.75)` || 'rgba(0, 0, 0, 0.5)'})`,
  // boxShadow: `inset 0 -70vh 70vh -5vh ${clr3 || 'rgba(0, 0, 0, .5)'}`,
  background: `linear-gradient(to right top, rgba(0,0,0,0.75), rgba(0,0,0,0.75))`,
  boxShadow: `inset 0 -30vh 50vh -5vh ${clr3 || 'rgba(0, 0, 0, .5)'}`,
});
