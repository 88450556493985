import React from 'react';
import './primaryTopviewHotspot.scss';

const primaryTopviewHotspot = ({ configsData, onClick }) => {
  const title1 = configsData?.title || '';
  const title2 = configsData?.subtitle || '';

  return (
    <div className="khp-primary-topview-hotspot" onClick={onClick}>
      <div className="khp-primary-topview-hotspot__title">
        <p className="title-01">{title1}</p>
        <p className="title-02">{title2}</p>
      </div>
    </div>
  );
};

export default primaryTopviewHotspot;
