export const SOCICAL_MEDIA = [
  'youtubeUrl',
  'websiteUrl',
  'email',
  'twitterUrl',
  'snapchatUrl',
  'pinterestUrl',
  'facebookUrl',
  'instagramUrl',
];
