export const PLAN_SCOPES = {
  NOT_ALLOWED: 'NOT_ALLOWED',
  UNLIMITED: 'UNLIMITED',
  NORMAL: 'NORMAL',
};

export const PLAN_SCOPE_KEYS = {
  SCOPE_TYPE: 'SCOPE_TYPE',
  TOUR_COUNT: 'TOUR_COUNT',
  SCENE_COUNT: 'SCENE_COUNT',
  MEDIA_COUNT: 'MEDIA_COUNT',
  USER_COUNT: 'USER_COUNT',
  SCENE_PER_TOUR_COUNT: 'SCENE_PER_TOUR_COUNT',
  MEDIA_PER_TOUR_COUNT: 'MEDIA_PER_TOUR_COUNT',
  ENABLE_BRANDING: 'ENABLE_BRANDING',
  ENABLE_ANALYTICS: 'ENABLE_ANALYTICS',
};

export const SCOPE_NAME = {
  TOUR: 'tour',
  SCENE: 'scene',
  MEDIA: 'media',
  USER: 'user',
};

export const DEFAULT_SCOPES_DATA = {
  scopeType: -1,
  tour: {
    value: -1,
  },
  scene: {
    value: -1,
    limitPerTour: -1,
  },
  media: {
    value: -1,
    limitPerTour: -1,
  },
  user: {
    value: -1,
  },
};
