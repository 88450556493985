import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

window.haveAudioSrc = false;

const AmbientAudio = (props) => {
  const { audios } = props;

  const audioRef = useRef();
  const autoPlay = useSelector((state) => state.audio.autoPlay);

  useEffect(() => {
    const src = audios.map((item) => item.soundUrl);
    window.haveAudioSrc = Boolean(src);
  }, [audios]);

  return (
    <>
      {audios.map((item, index) => {
        return (
          <audio
            ref={(ref) => (audioRef[index] = ref)}
            className="sceneAudio"
            autoPlay={autoPlay}
            loop={item.repeat}
            key={index}
            src={item.soundUrl}
          ></audio>
        );
      })}
    </>
  );
};

export default AmbientAudio;
