import { CanvasTexture, Texture } from 'three';
import { createCanvas, loadImageToCanvas } from 'utils/canvasHelper';
import { cleanLoadTextures } from 'utils/textureHelper';
import { SIDE } from './cube360/constants';

export const getCubeCanvases = () => {
  return {
    px: createCanvas(),
    nx: createCanvas(),
    py: createCanvas(),
    ny: createCanvas(),
    pz: createCanvas(),
    nz: createCanvas(),
  };
};

export const createCanvasTexture = (canvasDom) => {
  return new CanvasTexture(canvasDom);
};

export const createTexture = () => {
  return new Texture();
};

export const preloadImages = (imgs = []) => {
  return cleanLoadTextures(imgs);
};

export const loadImageToCanvasTexture = async (canvasDom, imgUrl) => {
  await loadImageToCanvas(canvasDom, imgUrl);
};

export const applyImageToCanvasTexture = async (
  canvasDom,
  texture,
  imgUrl,
  anisotropy
) => {
  await loadImageToCanvasTexture(canvasDom, imgUrl);
  texture.anisotropy = anisotropy;
  texture.needsUpdate = true;
};

export const applySceneTexture = async (
  canvases,
  textures,
  imgUrls,
  anisotropy,
  allAtOnce = false
) => {
  const requests = SIDE.map((side, index) => {
    if (!allAtOnce) {
      return applyImageToCanvasTexture(
        canvases[side],
        textures[side],
        imgUrls[index],
        anisotropy
      );
    }
    return loadImageToCanvasTexture(canvases[side], imgUrls[index]);
  });

  await Promise.all(requests);

  if (allAtOnce) {
    SIDE.forEach((side) => {
      textures[side].anisotropy = anisotropy;
      textures[side].needsUpdate = true;
    });
  }
};
